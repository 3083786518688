<template>
  <div>
    <div class="shadowBox titleBox">{{ errMsg8 }}</div>
    <div class="alert-danger alert">
      <p>{{ errMsgNoService }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import locales from "../mixins/locales.js";

export default {
  data() {
    return {};
  },
  mixins: [locales],
  computed: {
    ...mapGetters({
      globalLocale: "globalLocale"
    })
  },
  methods: {
    ...mapMutations(["setCompToRender"]),
    go2comp(location) {
      this.setCompToRender(location);
    }
  }
};
</script>

<style scoped>
.bnt-move {
  border-radius: 5px;
  padding: 5px;
  background-color: darkgray;
  color: white;
  font-weight: bold;
  height: 20px;
  line-height: 5px;
}
</style>
