<template>
  <div>
    <div class="shadowBox titleBox">
      {{ labelTitleFasifen }} - {{ itemInfo.UPDes }}
    </div>
    <div>
      <div>
        <button class="btn-primary" @click="chiudiTabella">
          {{ labelCloseBtn }}
        </button>
      </div>

      <div v-if="this.showMsg" class="alert alert-success marginMsg">
        <span>{{ this.insertMsg }}</span>
      </div>
      <div v-if="errors.length" class="alert alert-danger marginMsg">
        <b>{{ labelErrorMsg }}</b>
        <ul>
          <li v-for="(error, index) in errors" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>

      <table>
        <tr>
          <th class="up_item_header">
            <span class="vanish">{{ labelGestione }}</span>
          </th>
          <th class="up_item_header">{{ labelFasifen }}</th>
          <th class="up_item_header">{{ labelDataStimata }}</th>
          <th class="up_item_header">{{ labelDataOsservata }}</th>
        </tr>
        <tr
          class="up_item_row"
          :class="index % 2 == 0 ? 'evenRow' : 'oddRow'"
          v-for="(fase, index) in fasiList"
          :key="index"
        >
          <td class="center">
            <select
              class="custom-select-2"
              v-model="action"
              @change="onChangeAction(fase)"
            >
              <option></option>
              <option value="deleteFase">{{ labelCancella }}</option>
              <option value="editFase">{{ labelMod }}</option>
            </select>
            <i class="iconAgego editAgego"></i>
          </td>
          <td>
            {{ fase.descrizione }}
          </td>
          <td class="center">
            {{ customFormatter(fase.dataFaseModello) }}
          </td>
          <td class="center">
            <span v-if="!edit || formdata.fase_id != fase.IDFase">
              {{ customFormatter(fase.dataFaseUtente) }}
            </span>
            <div v-else-if="edit && formdata.fase_id == fase.IDFase">
              <datetime
                v-model="formdata.input_utente"
                :format="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              >
              </datetime>
              <div class="panelBtn">
                <div class="pull-left" @click="submit">
                  <i class="iconAgego confermaAgego"></i>
                </div>
                <div class="pull-right" @click="annulla">
                  <i class="iconAgego annullaAgego"></i>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <app-dialog />
      <!-- <div id="waitingPanel" class="waiting">
        <div class="headerWaiting">
          {{ labeWaiting }}
        </div>
        <div class="bodyWaiting">
          {{ labelElab }}
        </div>
        <div class="loaderIcon">
          <img src="/../images/agrishare/horta/ajax-loader.gif" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import queries from "../../mixins/queries.js";
import locales from "../../mixins/locales.js";

export default {
  data() {
    return {
      fasiList: [],
      action: "",
      edit: false,
      errors: [],
      formdata: {
        up_id: "",
        fase_id: "",
        anno_rif: "",
        input_utente: ""
      },
      showDatePicker: false
    };
  },
  mixins: [queries, locales],
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      itemInfo: "itemInfo",
      token: "token",
      showMsg: "showMsg",
      insertMsg: "insertMsg",
      globalLocale: "globalLocale"
    })
  },
  methods: {
    ...mapMutations([
      "setCompToRender",
      "setItemInfo",
      "setInsertMsg",
      "setShowMsg"
    ]),
    onChangeAction(fase) {
      this.formdata.input_utente = "";
      if (this.action === "editFase") {
        this.edit = true;
        this.formdata.up_id = this.itemInfo.UPID;
        this.formdata.fase_id = fase.IDFase;
        this.formdata.anno_rif = fase.annoRiferimento;
        this.formdata.input_utente = fase.dataFaseUtente;
      } else {
        this.deleteData(fase);
      }
      this.action = "";
    },
    chiudiTabella() {
      this.setCompToRender("compFenoUp");
    },
    fetchData() {
      this.retrieveFasiFen(this.token, this.globalLocale, this.itemInfo.UPID)
        .then(response => {
          this.fasiList = response.data.data?.fasiFenologiche;
          this.loaded();
        })
        .catch(error => {
          console.log(error);
        });
    },
    submit() {
      if (this.checkForm()) {
        this.mutationUpdateFasiFen(
          this.token,
          this.formdata.up_id,
          this.formdata.fase_id,
          this.formdata.anno_rif,
          this.customFormatterSQL(this.formdata.input_utente)
        )
          .then(response => {
            if (response.data?.errors?.length > 0) {
              this.setShowMsg(true);
              this.setInsertMsg(response.data?.errors?.[0].message);
            } else {
              this.setInsertMsg(this.labelOkSaveMsg);
              this.setShowMsg(true);
              this.fetchData();
              this.edit = false;
              setTimeout(() => {
                this.setShowMsg(false);
              }, 2000);
            }
          })
          .catch(error => {
            console.log(error);
            this.setInsertMsg(this.labelErrorSaveMsg);
            this.setShowMsg(true);
          });
      }
    },
    deleteData(fase) {
      this.mutationUpdateFasiFen(
        this.token,
        this.itemInfo.UPID,
        fase.IDFase,
        fase.annoRiferimento,
        "NONE"
      )
        .then(response => {
          if (response.data?.errors?.length > 0) {
            this.setShowMsg(true);
            this.setInsertMsg(response.data?.errors?.[0].message);
          } else {
            this.setInsertMsg(this.labelOkDeleteMsg);
            this.setShowMsg(true);
            this.fetchData();
            setTimeout(() => {
              this.setShowMsg(false);
            }, 2000);
          }
        })
        .catch(error => {
          console.log(error);
          this.setInsertMsg(this.labelErrorUPDeleteMsg);
          this.setShowMsg(true);
        });
    },
    annulla() {
      this.edit = false;
      this.fase_id = "";
      this.errors = [];
    },
    checkForm() {
      if (!this.formdata.input_utente) {
        this.errors.push(`${this.labelDataOsservata} - ${this.required}`);
        return false;
      }
      return true;
    },
    customFormatter(date) {
      if (date) return moment(date).format("DD/MM/YYYY");
      return date;
    },
    customFormatterSQL(date) {
      if (date) return moment(date).format("YYYY-MM-DD HH:mm:SS");
      return date;
    },
    loaded() {
      document.getElementById("waitingPanel").style.display = "none";
    }
  },
  created() {
    this.fetchData();
    setTimeout(() => {
      this.setShowMsg(false);
    }, 2000);
  },
  components: {
    Datetime
  }
};
</script>

<style>
.vdatetime {
  overflow: hidden;
}

.vdatetime-input {
  width: 100px;
  float: left;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.saveIcon {
  font-size: 19px;
}

.panelBtn {
  overflow: hidden;
  clear: both;
}
</style>
