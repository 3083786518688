<template>
  <div class="marginBox grigioscuro">
    <h2>{{ labelCerca }}</h2>

    <div class="adduser_item">
      <label>{{ labelUtente }}</label>
      <select
        class="form-control styledInput"
        v-model="example.IDCli"
        @change="listAziende()"
      >
        <option></option>
        <option
          v-for="(utente, index) in utenti"
          :key="index"
          :value="utente.IDCli"
        >
          {{ utente.rgsCliente }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelAzienda }}</label>
      <select class="form-control styledInput" v-model="example.pIVAAzi">
        <option></option>
        <option
          v-for="(azienda, index) in aziende"
          :key="index"
          :value="azienda.pIVAAzi"
          :disabled="azienda.disabled"
        >
          {{ azienda.rgsAzienda }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelDescrizione }}</label>
      <input
        class="form-control styledInput"
        type="text"
        v-model="example.UPDes"
      />
    </div>

    <div class="adduser_item">
      <label>{{ labelNazione }}</label>
      <select
        class="form-control styledInput"
        v-model="example.nazISO"
        @change="listRegioni()"
      >
        <option></option>
        <option
          v-for="(nazione, index) in nazioni"
          :key="index"
          :value="nazione.nazISO"
        >
          {{ nazione.nazDes }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelRegione }}</label>
      <select
        class="form-control styledInput"
        v-model="example.regione"
        @change="listProvince()"
      >
        <option
          v-for="(regione, index) in regioni"
          :key="index"
          :value="toIDRegione(regione)"
          :disabled="regione.disabled"
        >
          {{ toStringRegione(regione) }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelProvincia }}</label>
      <select
        class="form-control styledInput"
        v-model="example.provincia"
        @change="listComuni()"
      >
        <option
          v-for="(provincia, index) in province"
          :key="index"
          :value="toIDProvincia(provincia)"
          :disabled="provincia.disabled"
        >
          {{ toStringProvincia(provincia) }}
        </option>
      </select>
    </div>

    <div class="adduser_item" v-if="example.nazISO == 'IT'">
      <label>{{ labelComune }}</label>
      <select class="form-control styledInput" v-model="example.comIst">
        <option
          v-for="(comune, index) in comuni"
          :key="index"
          :value="comune.comIst"
          :disabled="comune.disabled"
        >
          {{ comune.comDes }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelStazione }}</label>
      <select class="form-control styledInput" v-model="example.quadranteID">
        <option></option>
        <option
          v-for="(quadrante, index) in quadranti"
          :key="index"
          :value="quadrante.quadranteID"
          :disabled="quadrante.disabled"
        >
          {{ quadrante.quadranteNome }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelSistema }}</label>
      <select
        class="form-control styledInput"
        v-model="example.sistemaColturaleID"
      >
        <option></option>
        <option
          v-for="(sistema, index) in sistColts"
          :key="index"
          :value="sistema.sistemaColturaleID"
        >
          {{ sistema.sistemaColturaleDes }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelVarieta }}</label>
      <select class="form-control styledInput" v-model="example.varCod">
        <option></option>
        <option
          v-for="(varieta, index) in varietas"
          :key="index"
          :value="varieta.varCod"
        >
          {{ varieta.varDes }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelCautelaPE }}</label>
      <select class="form-control styledInput" v-model="example.doseInoculoID">
        <option></option>
        <option
          v-for="(inoculo, index) in livelliCautela"
          :key="index"
          :value="inoculo.doseInoculoID"
        >
          {{ inoculo.doseInoculoDes }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelPresenzaMAN }}</label>
      <select
        class="form-control styledInput"
        v-model="example.presenzaMarciumeNero"
      >
        <option value="0">{{ labelNo }}</option>
        <option value="1">{{ labelSi }}</option>
      </select>
    </div>

    <p v-if="showMsg" class="info">
      <span>{{ textMsg }}</span>
    </p>
    <div v-if="errors.length" class="error">
      <b>{{ labelErrorMsg }}</b>
      <ul>
        <li v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </div>

    <hr />
    <button class="btn btn-primary" @click="submit">{{ labelCerca }}</button>
    <button class="btn btn-default" @click="annulla">{{ labelAnnulla }}</button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import queries from "../../mixins/queries.js";
import locales from "../../mixins/locales.js";

export default {
  data() {
    return {
      showMsg: false,
      textMsg: "",
      errors: [],
      utenti: [],
      aziende: [],
      nazioni: [],
      regioni: [],
      province: [],
      comuni: [],
      quadranti: [],
      sistColts: [],
      varietas: [],
      livelliCautela: [],
      example: {
        UPDes: "",
        quadranteID: "",
        IDCli: "",
        pIVAAzi: "",
        nazISO: "",
        regIst: "",
        proIst: "",
        comIst: "",
        sistemaColturaleID: "",
        varCod: "",
        doseInoculoID: "",
        presenzaMan: "",
        nuts2Code: "",
        nuts3Code: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      token: "token",
      reteID: "reteID",
      globalLocale: "globalLocale",
      varCodService: "varCodService"
    })
  },
  mixins: [queries, locales],
  methods: {
    ...mapMutations(["setCompToRender", "setExample", "toggleFilterOn"]),
    submit() {
      this.setExample(this.example);
      this.setCompToRender("compUp");
      this.toggleFilterOn(true);
    },
    fillDropDown() {
      this.retrieveUpDropDownInfo(
        this.token,
        this.globalLocale,
        this.varCodService,
        "",
        "",
        "",
        "",
        "",
        "",
        this.reteID ? this.reteID : "VT"
      )
        .then(response => {
          return response.data.data;
        })
        .then(data => {
          const listaUtenti = data.formNuovaUP?.listaUtenti;
          this.utenti = listaUtenti;
          const listaNazioni = data.formNuovaUP?.listaNazioni;
          this.nazioni = listaNazioni;
          const listaVarieta = data.formNuovaUP?.listaVarieta;
          this.varietas = listaVarieta;
          const listaSistemi = data.formNuovaUP?.listaSistemiColturali;
          this.sistColts = listaSistemi;
          const listaInoculi = data.formNuovaUP?.dosiInoculoPeronospora;
          this.livelliCautela = listaInoculi;
          const listaStazioni = data.formNuovaUP?.listaStazioni;
          if (listaStazioni.length > 0) this.quadranti = listaStazioni;
          else
            this.quadranti.push({
              quadranteNome: this.noItems,
              disabled: true
            });
        })
        .catch(error => {
          console.log(error);
        });
    },
    listAziende() {
      if (this.example.IDCli) {
        this.aziende = [];
        const idCli = this.example.IDCli;
        this.retrieveAziende(this.token, idCli)
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            const listaAziendeBy = data.formNuovaUP?.listaAziende;
            if (listaAziendeBy.length > 0) this.aziende = listaAziendeBy;
            else
              this.aziende.push(
                {
                  rgsAzienda: "",
                  disabled: true
                },
                {
                  rgsAzienda: this.noItems,
                  disabled: true
                }
              );
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.aziende.push({
          rgsAzienda: this.noUser,
          disabled: true
        });
      }
    },
    listRegioni() {
      this.regioni = [];
      if (this.example.nazISO) {
        const nazIso = this.example.nazISO;
        this.retrieveRegioni(
          this.token,
          nazIso == "IT" ? "" : nazIso,
          nazIso == "IT" ? "regDes" : "nuts2Des"
        )
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            let listaRegioniBy = [];
            if (nazIso == "IT") {
              listaRegioniBy = data.formNuovaUP?.listaRegioni?.regioni;
            } else {
              listaRegioniBy = data.formNuovaUP?.listaRegioni?.nutsLevel2;
            }

            this.regioni = listaRegioniBy;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.regioni.push(
          {
            regDes: "",
            disabled: true
          },
          {
            regDes: this.noCountry,
            disabled: true
          }
        );
      }
    },
    toStringRegione(regione) {
      if (regione.regDes) {
        return regione.regDes;
      } else if (regione.nuts2Des) {
        return regione.nuts2Des;
      }
    },
    toIDRegione(regione) {
      if (regione.regIst) {
        return regione.regIst;
      } else if (regione.nuts2Code) {
        return regione.nuts2Code;
      }
    },
    listProvince() {
      this.province = [];
      const nazIso = this.example.nazISO;
      if (this.example.regione) {
        this.retrieveProvince(
          this.token,
          nazIso == "IT" ? this.example.regione : "",
          nazIso == "IT" ? "" : this.example.regione,
          nazIso == "IT" ? "proDes" : "nuts3Des"
        )
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            let listaProvinceBy = {};
            if (nazIso == "IT") {
              listaProvinceBy = data.formNuovaUP?.listaProvince?.province;
            } else {
              listaProvinceBy = data.formNuovaUP?.listaProvince?.nutsLevel3;
            }
            this.province = listaProvinceBy;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.province.push(
          {
            proDes: "",
            disabled: true
          },
          {
            proDes: this.noRegione,
            disabled: true
          }
        );
      }
    },
    toStringProvincia(provincia) {
      if (provincia.proDes) {
        return provincia.proDes;
      } else if (provincia.nuts3Des) {
        return provincia.nuts3Des;
      }
    },
    toIDProvincia(provincia) {
      if (provincia.proIst) {
        return provincia.proIst;
      } else if (provincia.nuts3Code) {
        return provincia.nuts3Code;
      }
    },
    listComuni() {
      this.comuni = [];
      if (this.example.provincia) {
        this.retrieveComuni(this.token, this.example.provincia, "")
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            const listaComuniBy = data.formNuovaUP?.listaComuni;
            this.comuni = listaComuniBy;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.comuni.push(
          {
            comDes: "",
            disabled: true
          },
          {
            comDes: this.noProvincia,
            disabled: true
          }
        );
      }
    },
    cleanForm() {
      this.example = {
        UPDes: "",
        proIst: "",
        comIst: "",
        quadranteID: "",
        IDCli: "",
        pIVAAzi: "",
        nazISO: "",
        regIst: "",
        sistemaColturaleID: "",
        varCod: "",
        doseInoculoID: "",
        presenzaMan: "",
        nuts2Code: "",
        nuts3Code: ""
      };
    },
    annulla() {
      this.setCompToRender("compUp");
    }
  },
  created() {
    this.fillDropDown();
    this.listAziende();
    this.listRegioni();
    this.listProvince();
    this.listComuni();
  }
};
</script>

<style scoped>
.error {
  color: red;
}

.info {
  font-weight: bold;
}

select {
  width: 147px;
}

input[type="text"]:focus {
  background-color: rgb(180, 230, 173);
}
</style>
