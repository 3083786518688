<template>
  <div class="shadowBox marginBox">
    <h2>Modifica fase - {{ formdata.fase }}</h2>

    <div class="adduser_item">
      <label>Utente</label>
      <select class="form-control" v-model="formdata.id_cli">
        <option
          v-for="(utente, index) in utenti"
          :key="index"
          :value="utente.id_cli"
        >
          {{ utente.rgs }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>Ragione sociale</label>
      <input class="form-control" type="text" v-model="formdata.rgs" />
    </div>

    <div class="adduser_item">
      <label>Partita iva</label>
      <input class="form-control" type="text" v-model="formdata.piva_azi" />
    </div>

    <div class="adduser_item">
      <label>Nazione</label>
      <select
        class="form-control"
        v-model="formdata.naz_iso"
        @change="listRegioni()"
      >
        <option
          v-for="(nazione, index) in nazioni"
          :key="index"
          :value="nazione.naz_iso"
        >
          {{ nazione.naz_des }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>Regione</label>
      <select
        class="form-control"
        v-model="formdata.reg_ist"
        @change="listProvince()"
      >
        <option
          v-for="(regione, index) in regioni"
          :key="index"
          :value="regione.reg_ist"
        >
          {{ regione.reg_des }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>Provincia</label>
      <select
        class="form-control"
        v-model="formdata.pro_ist"
        @change="listComuni()"
      >
        <option
          v-for="(provincia, index) in province"
          :key="index"
          :value="provincia.pro_ist"
        >
          {{ provincia.pro_des }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>Comune</label>
      <select class="form-control" v-model="formdata.com_ist">
        <option
          v-for="(comune, index) in comuni"
          :key="index"
          :value="comune.com_ist"
        >
          {{ comune.com_des }}
        </option>
      </select>
    </div>

    <p v-if="showMsg" class="alert alert-success">
      <span>{{ textMsg }}</span>
    </p>
    <div v-if="errors.length" class="alert alert-danger">
      <b>Please correct the following error(s):</b>
      <ul>
        <li v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </div>

    <hr />
    <button class="btn btn-primary" @click="submit">Salva</button>
    <button class="btn btn-default" @click="annulla" v-if="!showNoAziende">
      Annulla
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
const axios = require("axios");

export default {
  data() {
    return {
      showMsg: false,
      textMsg: "",
      errors: [],
      utenti: [],
      nazioni: [],
      regioni: [],
      province: [],
      comuni: [],
      isEdit: false,
      formdata: {
        rgs: "",
        id_cli: "",
        piva_azi: "",
        naz_iso: "",
        reg_ist: "",
        pro_ist: "",
        com_ist: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      upInfo: "upInfo",
      provUrl: "provUrl",
      comUrl: "comUrl",
      regUrl: "regUrl",
      nazUrl: "nazUrl",
      utentiUrl: "utentiUrl",
      aziendeUrl: "aziendeUrl",
      showNoAziende: "showNoAziende"
    })
  },
  methods: {
    ...mapMutations(["setCompToRender"]),
    submit() {
      if (this.checkForm()) {
        axios
          .post(this.aziendeUrl, {
            rgs: this.formdata.rgs,
            id_cli: this.formdata.id_cli,
            piva_azi: this.formdata.piva_azi,
            naz_iso: this.formdata.naz_iso,
            reg_ist: this.formdata.reg_ist,
            pro_ist: this.formdata.pro_ist,
            com_ist: this.formdata.com_ist
          })
          .then(response => {
            this.textMsg = response.statusText;
            this.showMsg = true;
            this.cleanForm();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    listUtenti() {
      const url = `${this.utentiUrl}`;
      axios
        .get(url)
        .then(response => {
          this.utenti = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    listNazioni() {
      const url = `${this.nazUrl}`;
      axios
        .get(url)
        .then(response => {
          this.nazioni = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    listRegioni() {
      if (this.formdata.naz_iso) {
        const url = `${this.regUrl}`;
        let params = {};
        params["naz_iso"] = this.formdata.naz_iso;

        axios
          .get(url, {
            params: params
          })
          .then(response => {
            this.regioni = response.data;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    listProvince() {
      if (this.formdata.reg_ist) {
        const url = `${this.provUrl}`;
        let params = {};
        params["reg_ist"] = this.formdata.reg_ist;

        axios
          .get(url, {
            params: params
          })
          .then(response => {
            this.province = response.data;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    listComuni() {
      if (this.formdata.pro_ist) {
        const url = `${this.comUrl}`;
        let params = {};

        params["pro_ist"] = this.formdata.pro_ist;

        axios
          .get(url, {
            params: params
          })
          .then(response => {
            this.comuni = response.data;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    checkForm() {
      this.errors = [];

      if (
        this.formdata.rgs &&
        this.formdata.piva_azi &&
        this.formdata.id_cli &&
        this.formdata.naz_iso &&
        this.formdata.reg_ist &&
        this.formdata.pro_ist &&
        this.formdata.com_ist
      ) {
        return true;
      }

      if (!this.formdata.id_cli) {
        this.errors.push("L'utente è obbligatorio.");
      }
      if (!this.formdata.piva_azi) {
        this.errors.push("La partita iva è obbligatoria.");
      }
      if (!this.formdata.rgs) {
        this.errors.push("La ragione sociale è obbligatoria.");
      }
      if (!this.formdata.naz_iso) {
        this.errors.push("La nazione è obbligatoria.");
      }
      if (!this.formdata.reg_ist) {
        this.errors.push("La regione è obbligatoria.");
      }
      if (!this.formdata.pro_ist) {
        this.errors.push("La provincia è obbligatoria.");
      }
      if (!this.formdata.com_ist) {
        this.errors.push("Il comune è obbligatorio.");
      }

      //e.preventDefault();
    },
    cleanForm() {
      this.formdata = {
        rgs: "",
        pro_ist: "",
        com_ist: "",
        id_cli: "",
        piva_azi: "",
        naz_iso: "",
        reg_ist: ""
      };
    },
    annulla() {
      this.setCompToRender("compAziende");
    }
  },
  created() {
    this.listUtenti();
    this.listNazioni();

    if (this.upInfo) {
      this.isEdit = true;
      this.formdata = this.upInfo;
      this.listRegioni();
      this.listProvince();
      this.listComuni();
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
}

.info {
  font-weight: bold;
}

select {
  width: 147px;
}
</style>
