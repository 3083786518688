<template>
  <div class="chart"></div>
</template>

<script>
import Anychart from "anychart";

export default {
  props: ["options", "Anychart"],
  name: "VueAnychart",
  data() {
    return {
      chart: null,
      licenseKey: "crpa.it-ca145b44-d48102e4"
    };
  },
  mounted() {
    if (!this.chart && this.options) {
      this.init();
    }
  },
  methods: {
    removeSeries() {
      if (this.chart.getSeriesCount()) {
        this.chart.removeSeriesAt(0);
      }
    },
    removeAllSeries() {
      if (this.chart.getSeriesCount()) {
        this.chart.removeAllSeries();
      }
    },
    addSeries(data) {
      this.delegateMethod("addSeries", data);
    },
    delegateMethod(name, data) {
      if (!this.chart) {
        this.warn(
          `Cannot call [$name] before the chart is initialized. Set prop [options] first.`,
          this
        );
        return;
      }
      return this.chart[name](data);
    },
    init() {
      if (!this.chart && this.options) {
        const anychart = this.Anychart || Anychart;
        anychart.licenseKey(this.licenseKey);

        this.chart = new anychart.fromJson(this.options);
        this.chart.container(this.$el).draw();
      }
    }
  },
  watch: {
    options: function(options) {
      if (!this.chart && options) {
        this.init();
      } else {
        this.chart.dispose();
        this.chart = null;
        this.init();
      }
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  }
};
</script>
