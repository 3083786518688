<template>
  <div>
    <div class="shadowBox titleBox">
      {{ welcome }}
    </div>
    <div>
      <div v-if="this.showNoAziende" class="alert-warning alert">
        <p>Sembra che tu non abbia ancora creato un'azienda.</p>
        <p>
          Per poter usare Agrigenius Vite Go c'è bisogno di almeno un'azienda.
        </p>
        <p>
          Clicca
          <button class="bnt-move" @click="go2comp('compAddAziende')">
            qui
          </button>
          per creare la tua prima azienda.
        </p>
      </div>
      <div v-else-if="this.showNoUp" class="alert-warning alert">
        <p>{{ noUp1 }}</p>
        <p>{{ noUp2 }}</p>
        <p>
          {{ errMsg3 }}
          <button class="bnt-move" @click="go2comp('compAddUp')">
            {{ errMsg4 }}
          </button>
          {{ noUp3 }}
        </p>
      </div>
      <div v-else>
        {{ waiting }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import locales from "../mixins/locales.js";

export default {
  data() {
    return {
      title: "Benvenuto in Agrigenius Vite GO"
    };
  },
  mixins: [locales],
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      showNoAziende: "showNoAziende",
      showNoUp: "showNoUp",
      globalLocale: "globalLocale"
    })
  },
  methods: {
    ...mapMutations(["setCompToRender", "setShowNoAziende", "setShowNoUp"]),
    go2comp(location) {
      this.setCompToRender(location);
    },
    checkData() {}
  },
  created() {}
};
</script>

<style scoped>
.bnt-move {
  border-radius: 5px;
  padding: 5px;
  background-color: darkgray;
  color: white;
  font-weight: bold;
  height: 20px;
  line-height: 5px;
}
</style>
