<template>
  <div>
    <header>
      {{ headerTitle }}
    </header>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import locales from "../../mixins/locales.js";

export default {
  data() {
    return {};
  },
  mixins: [locales],
  computed: {
    ...mapGetters({
      globalLocale: "globalLocale"
    })
  }
};
</script>

<style>
header {
  font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
  background: #65ac1e;
  border-bottom: 4px solid #6f6f6e;
  box-sizing: border-box;
  padding: 10px;
  color: #ffffff;
  font-size: 30px;
  margin: auto;
}
</style>
