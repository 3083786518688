<template>
  <div>
    <h1>Sito in manutenzione</h1>
    <p>
      Al fine di garantirvi un'esperienza d'uso migliorata, nella giornata
      odierna l'accesso ai servizi riservati sarà disabilitato per lavori di
      manutenzione straordinaria.
    </p>
    <p>
      Sarà possibile consultare i servizi a partire dal 25/01. Ci scusiamo per
      l'inconveniente.
    </p>

    <h1>Site under maintenance</h1>
    <p>
      The reserved services of Horta s.r.l. are currently under maintenance
      until the 25th of January.
    </p>
    <p>We apologise for the inconvenience.</p>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  methods: {
    ...mapMutations(["setCompToRender"])
  },
  computed: {
    ...mapGetters({})
  }
};
</script>
