var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showHeader)?_c('app-header'):_vm._e(),_c('div',{staticClass:"container",class:_vm.showHeader ? '' : 'containerNoHeader',attrs:{"id":"main"}},[(
        _vm.compToRender !== 'compHome' &&
          _vm.compToRender !== 'compLogin' &&
          _vm.compToRender !== 'compLoginGigya' &&
          _vm.compToRender !== 'compDisclaimer' &&
          _vm.compToRender !== 'compError' &&
          _vm.compToRender !== 'compErrorLogin' &&
          _vm.compToRender !== 'compPwa' &&
          _vm.compToRender !== 'compRegister' &&
          _vm.compToRender !== 'compErrorNoService'
      )?_c('div',{staticClass:"shadowBox",attrs:{"id":"toolbarTop"}},[(_vm.compToRender.indexOf('Up') > -1 && _vm.compToRender !== 'compUp')?_c('div',{staticClass:"flex",attrs:{"id":"btnsUp"}},[_c('div',{staticClass:"icoToolbar indietroAgego",class:_vm.itemInfo.UPID ? '' : 'aloneIco',on:{"click":function($event){return _vm.onChangeTabset('compUp')}}}),(_vm.itemInfo.UPID)?_c('div',{staticClass:"icoToolbar",class:_vm.compToRender === 'compMeteoUp'
              ? 'active meteoWhiteAgego'
              : 'meteoAgego',on:{"click":function($event){return _vm.onChangeTabset('compMeteoUp')}}}):_vm._e(),(_vm.itemInfo.UPID)?_c('div',{staticClass:"icoToolbar",class:_vm.compToRender === 'compFenoUp'
              ? 'active fenologiaWhiteAgego'
              : 'fenologiaAgego',on:{"click":function($event){return _vm.onChangeTabset('compFenoUp')}}}):_vm._e(),(_vm.itemInfo.UPID)?_c('div',{staticClass:"icoToolbar",class:_vm.compToRender === 'compMalattieUp'
              ? 'active malattieWhiteAgego'
              : 'malattieAgego',on:{"click":function($event){return _vm.onChangeTabset('compMalattieUp')}}}):_vm._e(),(_vm.itemInfo.UPID)?_c('div',{staticClass:"icoToolbar",class:_vm.compToRender === 'compInsettiUp'
              ? 'active insettiWhiteAgego'
              : 'insettiAgego',on:{"click":function($event){return _vm.onChangeTabset('compInsettiUp')}}}):_vm._e(),(_vm.itemInfo.UPID)?_c('div',{staticClass:"icoToolbar",class:_vm.compToRender === 'compFitoUp'
              ? 'active fitoWhiteAgego2'
              : 'fitoAgego2',on:{"click":function($event){return _vm.onChangeTabset('compFitoUp')}}}):_vm._e(),(_vm.itemInfo.UPID)?_c('div',{staticClass:"icoToolbar",class:_vm.compToRender === 'compProtUp'
              ? 'active protezioneWhiteAgego'
              : ' protezioneAgego',on:{"click":function($event){return _vm.onChangeTabset('compProtUp')}}}):_vm._e(),(_vm.itemInfo.UPID)?_c('div',{staticClass:"icoToolbar",class:_vm.compToRender === 'compRocUp' ? 'active rocWhiteAgego' : 'rocAgego',on:{"click":function($event){return _vm.onChangeTabset('compRocUp')}}}):_vm._e()]):_vm._e(),(_vm.compToRender === 'compUp')?_c('div',{staticClass:"flex",attrs:{"id":"sectionSelect"}},[_c('div',{staticClass:"icoMainToolbar aziendeAgego",on:{"click":function($event){return _vm.onChange('compAziende')}}}),_c('div',{staticClass:"icoMainToolbar addAgego",on:{"click":function($event){return _vm.onChange('compAddUp')}}}),_c('div',{staticClass:"icoMainToolbar filterAgego",on:{"click":function($event){return _vm.onChange('compFilterUp')}}}),(_vm.filterOn)?_c('div',{staticClass:"icoMainToolbar cleanfilterAgego",on:{"click":function($event){return _vm.onChange('cleanFiltri')}}}):_vm._e(),(!_vm.isActive)?_c('div',{staticClass:"icoMainToolbar attiveAgego",on:{"click":function($event){return _vm.onChange('showUpAttive')}}}):_vm._e(),(_vm.isActive)?_c('div',{staticClass:"icoMainToolbar scaduteAgego",on:{"click":function($event){return _vm.onChange('showUpScadute')}}}):_vm._e()]):_vm._e(),(_vm.compToRender.indexOf('Aziende') > -1)?_c('div',{staticClass:"flex",attrs:{"id":"sectionSelectAziende"}},[_c('div',{staticClass:"icoAzToolbar elencoUpAgego",on:{"click":function($event){return _vm.onChange('compUp')}}}),(_vm.compToRender !== 'compAziende')?_c('div',{staticClass:"icoAzToolbar indietroAgego",on:{"click":function($event){return _vm.onChange('compAziende')}}}):_vm._e(),(
            _vm.compToRender !== 'compAddAziende' &&
              _vm.compToRender !== 'compProdottiAziende' &&
              _vm.compToRender !== 'compFilterAziende'
          )?_c('div',{staticClass:"icoAzToolbar addAgego",on:{"click":function($event){return _vm.onChange('compAddAziende')}}}):_vm._e(),(
            _vm.compToRender !== 'compAddAziende' &&
              _vm.compToRender !== 'compProdottiAziende' &&
              _vm.compToRender !== 'compFilterAziende'
          )?_c('div',{staticClass:"icoAzToolbar filterAgego",on:{"click":function($event){return _vm.onChange('compFilterAziende')}}}):_vm._e(),(_vm.filterOn)?_c('div',{staticClass:"icoAzToolbar cleanfilterAgego",on:{"click":function($event){return _vm.onChange('cleanFiltriAziende')}}}):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{attrs:{"id":this.compToRender}},[_c(this.compToRender,{tag:"component"})],1)]),(
      _vm.compToRender !== 'compLogin' &&
        _vm.compToRender !== 'compLoginGigya' &&
        _vm.compToRender !== 'compRegister'
    )?_c('app-footer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }