<template>
  <div id="waitingPanel" class="bg">
    <div class="waiting">
      <div class="headerWaiting">
        {{ labeWaiting }}
      </div>
      <div class="bodyWaiting">
        {{ labelElab }}
      </div>
      <div class="loaderIcon">
        <img src="/../images/agrishare/horta/ajax-loader.gif" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import locales from "../mixins/locales.js";

export default {
  mixins: [locales],
  computed: {
    ...mapGetters({
      globalLocale: "globalLocale"
    })
  }
};
</script>

<style scoped>
.bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #8080807d;
  height: 100%;
  width: 100%;
}
</style>
