<template>
  <div>
    <div class="shadowBox titleBox">
      {{ labelTitleAZPaginator }}
    </div>
    <div>
      <div class="flex">
        <div class="paginatorOuter">
          <div :disabled="isDisabledPrima" @click="prima" class="pullLeft">
            <i class="navPrimaAgego iconAgego"></i>
          </div>
          <div
            :disabled="isDisabledIndietro"
            @click="indietro"
            style="margin-left: 2px;"
            class="pullLeft"
          >
            <i class="navIndietroAgego iconAgego"></i>
          </div>
        </div>

        <div class="paginatorInner iconAgego">
          {{ currentPage }} / {{ maxPage }}
        </div>

        <div class="paginatorOuter">
          <div :disabled="isDisabledUltima" @click="ultima" class="pullRight">
            <i class="navUltimaAgego iconAgego"></i>
          </div>
          <div
            :disabled="isDisabledAvanti"
            @click="avanti"
            class="pullRight"
            style="margin-right: 2px;"
          >
            <i class="navAvantiAgego iconAgego"></i>
          </div>
        </div>
      </div>

      <div v-if="this.showMsg" class="alert alert-success marginMsg">
        <span>{{ this.insertMsg }}</span>
      </div>

      <table>
        <tr>
          <th class="up_item_header">{{ labelGestione }}</th>
          <th class="up_item_header">{{ labelRGS }}</th>
          <th class="up_item_header">{{ labelUtente }}</th>
          <th class="up_item_header">{{ labelFunz }}</th>
        </tr>
        <tr
          class="up_item_row"
          :class="index % 2 == 0 ? 'evenRow' : 'oddRow'"
          v-for="(aziende, index) in itemList"
          :key="index"
        >
          <td class="center">
            <div @click="openNav(`${aziende.IDCli}_${aziende.pIVAAzi}`)">
              <i class="iconAgego editAgego"></i>
            </div>
            <div
              :id="`mySidebar_${aziende.IDCli}_${aziende.pIVAAzi}`"
              class="sidebar sidebarLeft shadow"
            >
              <div class="sideBarTitle">
                {{ aziende.rgsAzienda }}
                <div
                  @click="closeNav(`${aziende.IDCli}_${aziende.pIVAAzi}`)"
                  class="closeSidebarIcon"
                >
                  &#0215;
                </div>
              </div>
              <div @click="onClickAction(aziende, 'deleteAzienda')">
                <i class="cancellaAgego sideBarIcon"></i>
                <a>{{ labelCancella }}</a>
              </div>
              <div @click="onClickAction(aziende, 'viewAzienda')">
                <i class="rocAgego sideBarIcon"></i>
                <a>{{ labelScheda }}</a>
              </div>
              <div @click="onClickAction(aziende, 'editAzienda')">
                <i class="editAgego sideBarIcon"></i>
                <a>{{ labelMod }}</a>
              </div>
            </div>
          </td>
          <td>{{ aziende.rgsAzienda }}</td>
          <td>{{ aziende.rgsCliente }}</td>
          <td class="center">
            <div @click="openFunz(`${aziende.IDCli}_${aziende.pIVAAzi}`)">
              <font-awesome-icon :icon="['fas', 'bars']" class="barsAgego" />
            </div>
            <div
              :id="`funzSidebar_${`${aziende.IDCli}_${aziende.pIVAAzi}`}`"
              class="sidebar sidebarRight shadow"
            >
              <div class="sideBarTitle">
                {{ aziende.rgsAzienda }}
                <div
                  @click="closeFunz(`${aziende.IDCli}_${aziende.pIVAAzi}`)"
                  class="closeSidebarIcon"
                >
                  &#0215;
                </div>
              </div>
              <div @click="onChange(aziende, 'compProdottiAziende')">
                <i class="preferitiAgego sideBarIcon"></i>
                <a>{{ labelListProdotti }}</a>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <app-dialog />
      <!-- <div id="waitingPanel" class="waiting">
        <div class="headerWaiting">
          {{ labeWaiting }}
        </div>
        <div class="bodyWaiting">
          {{ labelElab }}
        </div>
        <div class="loaderIcon">
          <img src="/../images/agrishare/horta/ajax-loader.gif" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import queries from "../../mixins/queries.js";
import locales from "../../mixins/locales.js";

export default {
  data() {
    return {
      title: "Elenco aziende",
      azSection: "",
      action: "",
      listaAziende: []
    };
  },
  mixins: [queries, locales],
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      aziendeUrl: "aziendeUrl",
      example: "example",
      itemList: "itemList",
      maxPage: "maxPage",
      currentPage: "currentPage",
      totalItem: "totalItem",
      currentSection: "currentSection",
      itemInfo: "itemInfo",
      limit: "limit",
      token: "token",
      totalList: "totalList",
      insertMsg: "insertMsg",
      showMsg: "showMsg",
      isDisabledAvanti: "isDisabledAvanti",
      isDisabledIndietro: "isDisabledIndietro",
      isDisabledPrima: "isDisabledPrima",
      isDisabledUltima: "isDisabledUltima",
      globalLocale: "globalLocale"
    })
  },
  methods: {
    ...mapMutations([
      "setCompToRender",
      "setToken",
      "setItemList",
      "setMaxPage",
      "setCurrentPage",
      "setTotalItem",
      "setCurrentSection",
      "setItemInfo",
      "setProdottiPreferitiList",
      "setTotalList",
      "setShowMsg",
      "setInsertMsg",
      "setIsDisabledAvanti",
      "setIsDisabledIndietro",
      "setIsDisabledPrima",
      "setIsDisabledUltima"
    ]),
    onClickAction(aziende, action) {
      this.closeall();

      const isDelete = action === "deleteAzienda";
      const isView = action === "viewAzienda";
      const isEdit = action === "editAzienda";

      this.setItemInfo(aziende);
      if (aziende) {
        if (isDelete) {
          this.openDeleteDialog(aziende);
        }
        if (isView) {
          this.viewAzienda();
        }
        if (isEdit) {
          this.updateAzienda();
        }
        this.action = "";
      }
    },
    onChange(aziende, action) {
      if (action) {
        this.setItemInfo(aziende);
        this.setCurrentSection(action);
        this.setCompToRender(action);
        this.setProdottiPreferitiList(aziende.prodotti);
        this.closeall();
      }
    },
    onChangeAction(aziende) {
      this.setItemInfo(aziende);
      const isDelete = this.action === "deleteAzienda";
      const isView = this.action === "viewAzienda";
      const isEdit = this.action === "editAzienda";
      if (aziende) {
        if (isDelete) {
          this.openDeleteDialog(aziende);
        }
        if (isView) {
          this.viewAzienda();
        }
        if (isEdit) {
          this.updateAzienda();
        }
        this.action = "";
      }
    },
    viewAzienda() {
      this.setCompToRender("compViewAziende");
    },
    updateAzienda() {
      this.setCompToRender("compAddAziende");
    },
    deleteAzienda(item) {
      this.mutationDeleteAzienda(this.token, item.IDCli, item.pIVAAzi)
        .then(result => {
          this.setShowMsg(true);
          if (result.data?.errors?.length > 0) {
            const error = result.data?.errors?.[0];
            if (error?.message.indexOf("attached") > -1)
              this.setInsertMsg(this.labelErrorUPDeleteMsg);
            else this.setInsertMsg(this.labelErrorDeleteMsg);
          } else {
            this.setInsertMsg(this.labelOkDeleteMsg);
            this.fetchData();
            setTimeout(() => {
              this.setShowMsg(false);
            }, 2000);
          }
        })
        .catch(error => {
          console.log(error);
          this.setShowMsg(true);
          this.setInsertMsg(this.labelErrorDeleteMsg);
        });
    },
    openDeleteDialog(azienda) {
      let message = {
        title: this.labelWarn,
        body: `${this.labelDialogAziendaDelete}: ${azienda.rgsAzienda}?`
      };

      let options = {
        okText: this.labelOkBtn,
        cancelText: this.labelCloseBtn
      };

      this.$dialog
        .confirm(message, options)
        .then(() => {
          this.deleteAzienda(azienda);
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchData() {
      this.setTotalList([]);
      this.setItemList([]);

      if (!this.isExampleEnabled())
        return this.retrieveMasterAziende(this.token)
          .then(result => {
            this.retrieveAziende(result);
          })
          .catch(error => {
            console.log(error);
          });
      else
        return this.retrieveMasterAziendeFiltred(this.token, this.example)
          .then(result => {
            this.retrieveAziende(result);
          })
          .catch(error => {
            console.log(error);
          });
    },
    retrieveAziende(result) {
      const listAziende = result.data?.data?.masterAziende;
      const countAziende = listAziende.length;
      this.setTotalItem(countAziende);
      if (countAziende > 0) {
        this.setMaxPage(Math.ceil(this.totalItem / this.limit));
      } else this.setMaxPage(1);

      this.setTotalList(listAziende);
      this.slicingList(this.totalList);
      this.checkDisabled();
      this.loaded();

      if (this.currentPage > this.maxPage) {
        this.setCurrentPage(1);
        this.slicingList(this.totalList);
        this.fetchData();
      }
    },
    slicingList(list) {
      const slicedList = list.slice(
        this.limit * (this.currentPage - 1),
        this.limit * this.currentPage
      );
      this.setItemList(slicedList);
    },
    avanti() {
      if (!this.isDisabledAvanti) {
        this.setCurrentPage(this.currentPage + 1);
        this.slicingList(this.totalList);
        this.checkDisabled();
      }
    },
    indietro() {
      if (!this.isDisabledIndietro) {
        this.setCurrentPage(this.currentPage - 1);
        this.slicingList(this.totalList);
        this.checkDisabled();
      }
    },
    prima() {
      this.setCurrentPage(1);
      this.slicingList(this.totalList);
      this.checkDisabled();
    },
    ultima() {
      this.setCurrentPage(this.maxPage);
      this.slicingList(this.totalList);
      this.checkDisabled();
    },
    checkDisabled() {
      this.setIsDisabledAvanti(this.currentPage >= this.maxPage);
      this.setIsDisabledIndietro(this.currentPage <= 1);
      this.setIsDisabledPrima(this.currentPage <= 1);
      this.setIsDisabledUltima(this.currentPage >= this.maxPage);
    },
    isExampleEnabled() {
      return (
        this.example.rgsAzienda ||
        this.example.IDCli ||
        this.example.pIVAAzi ||
        this.example.nazISO ||
        this.example.proIst ||
        this.example.comIst
      );
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    loaded() {
      document.getElementById("waitingPanel").style.display = "none";
    },
    openNav(item) {
      this.closeall();
      document.getElementById("mySidebar_" + item).style.width = "250px";
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768)
        document.getElementById("main").style.marginLeft = "250px";
    },
    closeNav(item) {
      document.getElementById("mySidebar_" + item).style.width = "0";
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768) document.getElementById("main").style.margin = "0";
    },
    openFunz(item) {
      this.closeall();
      document.getElementById("funzSidebar_" + item).style.width = "250px";
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768)
        document.getElementById("main").style.marginRight = "250px";
    },
    closeFunz(item) {
      document.getElementById("funzSidebar_" + item).style.width = "0";
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768) document.getElementById("main").style.margin = "0";
    },
    closeall() {
      this.closeAllSide();
      this.closeAllFunz();
      this.closeMainSidebar();
    },
    closeAllSide() {
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768) document.getElementById("main").style.margin = "0";
      const x = document.querySelectorAll('[id^="mySidebar_"]');
      for (const value of x) {
        value.style.width = "0";
      }
    },
    closeAllFunz() {
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768) document.getElementById("main").style.margin = "0";
      const y = document.querySelectorAll('[id^="funzSidebar"]');
      for (const value of y) {
        value.style.width = "0";
      }
    },
    closeMainSidebar() {
      // document.getElementById("mainSideBar").style.width = "0";
      // var width =
      //   window.innerWidth ||
      //   document.documentElement.clientWidth ||
      //   document.body.clientWidth;
      // if (width >= 768) document.getElementById("main").style.marginLeft = "0";
    },
    handleTouchStart(evt) {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
    },
    handleTouchMove(evt) {
      const widthWindow = window.width;
      if (!this.xDown || !this.yDown || widthWindow > 1024) {
        return;
      }

      const xUp = evt.touches[0].clientX;
      const yUp = evt.touches[0].clientY;

      const xDiff = this.xDown - xUp;
      const yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          /* left swipe */
          this.closeAllSide();
        } else {
          /* right swipe */
        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
          this.closeall();
        } else {
          /* down swipe */
        }
      }
      /* reset values */
      this.xDown = null;
      this.yDown = null;
    }
  },
  created() {
    this.fetchData();
    this.scrollToTop();
    setTimeout(() => {
      this.setShowMsg(false);
    }, 2000);

    document.addEventListener("touchstart", this.handleTouchStart, false);
    document.addEventListener("touchmove", this.handleTouchMove, false);
  }
};
</script>

<style scoped></style>
