<template>
  <div class="marginBox grigioscuro">
    <h2>{{ labelTitle }} - {{ itemInfo.UPDes }}</h2>

    <div class="adduser_item">
      <label>{{ labelUtente }}</label>
      <span>{{ itemInfo.rgsCliente }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelAzienda }}</label>
      <span>{{ itemInfo.rgsAzienda }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelDescrizione }}</label>
      <span>{{ itemInfo.UPDes }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelSuperficie }}</label>
      <span>{{ itemInfo.UPSup }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelNazione }}</label>
      <span>{{ itemInfo.nazDes }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelRegione }}</label>
      <span v-if="itemInfo.nazISO == 'IT'">{{ itemInfo.regDes }}</span>
      <span v-else>{{ itemInfo.nuts2Des }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelProvincia }}</label>
      <span v-if="itemInfo.nazISO == 'IT'">{{ itemInfo.proDes }}</span>
      <span v-else>{{ itemInfo.nuts3Des }}</span>
    </div>

    <div class="adduser_item" v-if="itemInfo.nazISO == 'IT'">
      <label>{{ labelComune }}</label>
      <span>{{ itemInfo.comDes }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelStazione }} </label>
      <span>{{ itemInfo.quadranteDes }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelLatitudine }}</label>
      <span>{{ itemInfo.latitudine }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelLongitudine }}</label>
      <span>{{ itemInfo.longitudine }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelSistema }}</label>
      <span>{{ itemInfo.sistemaColturaleDes }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelVarieta }}</label>
      <span>{{ itemInfo.varDes }}</span>
    </div>

    <!-- <div class="adduser_item">
      <label>{{ labelPressioneBot }}</label>
      <span>{{ itemInfo.livelloPressioneBotriteDes }}</span>
    </div> -->

    <div class="adduser_item">
      <label>{{ labelCautelaPE }}</label>
      <span>{{ itemInfo.doseInoculoPeronosporaDes }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelPresenzaMAN }}</label>
      <span>{{ itemInfo.presenzaMarciumeNero == 1 ? "Sì" : "No" }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import locales from "../../mixins/locales.js";

export default {
  data() {
    return {};
  },
  mixins: [locales],
  computed: {
    ...mapGetters({
      itemInfo: "itemInfo",
      globalLocale: "globalLocale"
    })
  },
  methods: {}
};
</script>

<style scoped>
.adduser_item {
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  width: 100%;
  padding: 10px;
  margin: auto;
  margin-bottom: 10px;
  background: #f7f7f7;
}

.adduser_item input {
  float: right;
}

.adduser_item label {
  width: 50%;
  display: inline-block;
}
</style>
