<template>
  <div>
    <div class="shadowBox titleBox">
      {{ labelTitleProdotti }}: {{ this.itemInfo.rgsAzienda }}
    </div>
    <div class="calcHeight">
      <div v-if="this.showMsg" class="alert alert-success marginMsg">
        <span>{{ this.msg }}</span>
      </div>
      <table>
        <tr>
          <th class="up_item_header ico"></th>
          <th class="up_item_header">{{ labelNomeProdotti }}</th>
          <th class="up_item_header">{{ labelAvversitaProdotti }}</th>
        </tr>
        <tr v-if="prodottiPreferitiList.length == 0">
          <td colspan="5" class="noItems">
            <span>{{ noProducts }}</span>
          </td>
        </tr>
        <tr
          class="up_item_row"
          :class="index % 2 == 0 ? 'evenRow' : 'oddRow'"
          v-for="(prodotti, index) in prodottiPreferitiList"
          :key="index"
        >
          <td class="center">
            <button class="btnRow" @click="openDeleteDialog(prodotti)">
              <i class="iconAgego cancellaAgego"></i>
            </button>
          </td>
          <td>{{ prodotti.nome }} ({{ prodotti.distributoreDes }})</td>
          <td>{{ prodotti.avversitaDes }}</td>
        </tr>
        <tr>
          <td class="up_item_header center">
            <button class="btnRow" @click="aggiungiProdotto">
              <i class="iconAgego addAgego"></i>
            </button>
          </td>
          <td class="up_item_header">
            <!-- <vue-select v-model="newProduct" :options="fullList"> </vue-select> -->

            <v-select
              label="nome"
              :options="paginated"
              v-model="newProduct"
              class="productSelect"
              @search="query => (search = query)"
              :filterable="false"
            >
              <template #option="{ nome, distributoreDes }">
                <div>{{ nome }}</div>
                <div>({{ distributoreDes }})</div>
              </template>
              <li slot="list-footer" class="pagination">
                <button @click="offset -= 10" :disabled="!hasPrevPage">
                  Prev
                </button>
                <button @click="offset += 10" :disabled="!hasNextPage">
                  Next
                </button>
              </li>
            </v-select>

            <!-- <select
              class="form-control productSelect js-example-basic-single"
              v-model="newProduct"
            >
              <option
                v-for="(prodotto, index) in prodottiList"
                :key="index"
                :value="prodotto"
              >
                {{ prodotto.nome }} ({{ prodotto.distributoreDes }})
              </option>
            </select> -->
          </td>
          <td class="up_item_header"></td>
        </tr>
      </table>

      <app-dialog />
      <!-- <div id="waitingPanel" class="waiting">
        <div class="headerWaiting">
          {{ labeWaiting }}
        </div>
        <div class="bodyWaiting">
          {{ labelElab }}
        </div>
        <div class="loaderIcon">
          <img src="/../images/agrishare/horta/ajax-loader.gif" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import queries from "../../mixins/queries.js";
import locales from "../../mixins/locales.js";

export default {
  data() {
    return {
      newProduct: "",
      showMsg: false,
      msg: "",
      prodottiList: [],
      prodottiPreferitiList: [],
      offset: 0,
      limit: 5,
      search: ""
    };
  },
  mixins: [queries, locales],
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      itemInfo: "itemInfo",
      token: "token",
      globalLocale: "globalLocale"
    }),
    filtered() {
      return this.prodottiList.filter(product =>
        product.nome.includes(this.search)
      );
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset);
    },
    hasNextPage() {
      const nextOffset = this.offset + 5;
      return Boolean(
        this.filtered.slice(nextOffset, this.limit + nextOffset).length
      );
    },
    hasPrevPage() {
      const prevOffset = this.offset - 5;
      return Boolean(
        this.filtered.slice(prevOffset, this.limit + prevOffset).length
      );
    }
  },
  methods: {
    ...mapMutations(["setCompToRender"]),
    listProdottixAziende() {
      this.retrieveMasterProdottiPreferitiAzienda(
        this.token,
        this.globalLocale,
        this.itemInfo.IDCli,
        this.itemInfo.pIVAAzi
      )
        .then(result => {
          this.prodottiPreferitiList =
            result.data?.data?.masterProdottiPreferiti;
          this.loaded();
        })
        .catch(error => {
          console.log(error);
        });
    },
    listProdotti() {
      this.retrieveMasterProdotti(this.token, this.itemInfo.nazISO)
        .then(result => {
          this.prodottiList = result.data?.data?.masterProdotti;
        })
        .catch(error => {
          console.log(error);
        });
    },
    aggiungiProdotto() {
      if (!this.newProduct) {
        this.createMsg(this.selectProductWarn);
        return;
      }

      let check = this.prodottiPreferitiList.filter(elm => {
        if (elm.prodottoID == this.newProduct.prodottoID) {
          return elm; // returns length = 1 (if object exists in array)
        }
      });

      if (check.length > 0) {
        this.createMsg(this.duplicatedProductWarn);
        this.newProduct = "";
        return;
      }

      this.mutationInsertProdottoPreferito(
        this.token,
        this.newProduct.prodottoID,
        this.itemInfo.IDCli,
        this.itemInfo.pIVAAzi
      )
        .then(result => {
          if (result.data?.errors?.length > 0) {
            this.createMsg(result.data?.errors?.[0].message, true);
            return;
          }
          this.createMsg(this.labelOkSaveMsg, false);
          this.newProduct = "";
          this.listProdottixAziende();
        })
        .catch(error => {
          console.log(error);
          this.createMsg(this.labelErrorSaveMsg, true);
        });
    },
    openDeleteDialog(prodotto) {
      let message = {
        title: this.labelWarn,
        body: `${this.labelDialogProdottoDelete}: ${prodotto.nome}?`
      };
      let options = {
        okText: this.labelOkBtn,
        cancelText: this.labelCloseBtn
      };
      this.$dialog
        .confirm(message, options)
        .then(() => {
          this.deleteProdotto(prodotto.prodottoID);
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteProdotto(prodottoID) {
      this.mutationDeleteProdottoPreferito(
        this.token,
        prodottoID,
        this.itemInfo.IDCli,
        this.itemInfo.pIVAAzi
      )
        .then(result => {
          if (result.data?.errors?.length > 0) {
            this.createMsg(result.data?.errors?.[0].message, true);
            return;
          }
          this.createMsg(this.labelOkDeleteMsg, true);
          this.listProdottixAziende();
        })
        .catch(error => {
          console.log(error);
          this.createMsg(this.labelErrorDeleteMsg, true);
        });
    },
    loaded() {
      if (document.getElementById("waitingPanel") != null)
        document.getElementById("waitingPanel").style.display = "none";
    },
    createMsg(txt, persist) {
      this.msg = txt;
      this.showMsg = true;
      if (!persist) {
        setTimeout(() => {
          this.showMsg = false;
        }, 2000);
      }
    }
  },
  created() {
    this.listProdottixAziende();
    this.listProdotti();
    this.loaded();
  }
};
</script>

<style scoped>
.total_user {
  overflow: hidden;
  clear: both;
}

.btnRow {
  background: none;
  padding: 0px;
  margin: 0px;
  border: none;
}

.ico {
  width: 30px;
}

.productSelect {
  width: 100%;
  background-color: white;
}

.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
  background-color: #65ac1e !important;
  color: white !important;
  padding: 5px;
  border: 1px solid white;
}

.pagination button:hover {
  cursor: pointer;
}
</style>
