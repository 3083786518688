<template>
  <div>
    <div class="shadowBox titleBox">{{ labelFito }} - {{ itemInfo.UPDes }}</div>
    <div>
      <div>
        <iframe id="iframe" class="iframeFito" :src="fitoUrl"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { store } from "../Store/store.js";
import locales from "../mixins/locales.js";
import queries from "../mixins/queries.js";

export default {
  data() {
    return {
      fitoUrl: "",
      isReady: false
    };
  },
  mixins: [queries, locales],
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      itemInfo: "itemInfo",
      globalLocale: "globalLocale"
    })
  },
  methods: {
    onload() {
      this.isReady = true;
    }
  },
  created() {
    this.isReady = false;
    this.fitoUrl = `/horta_agrofarm/faces/index.html?upID=${this.itemInfo.UPID}&specie=05&serv_id_in=${store.state.servIdIn}&lang=${this.globalLocale}&agrigenius=true`;
  }
};
</script>

<style>
.iframeFito {
  border: 0;
  width: 100%;
  height: 100vh;
}
</style>
