<template>
  <div>
    <div class="shadowBox titleBox">
      {{ labelMalattie }} - {{ labelEvo }} - {{ itemInfo.UPDes }}
    </div>
    <div>
      <div
        id="container"
        class="chartPanel shadowBox calcHeight"
        style="display:none;"
      >
        <vue-anychart :options="option" ref="protectionChart"></vue-anychart>

        <div class="bold legendPanelMalattie grigioscuro">{{ labelPE }}</div>
        <div class="containerFlex legendPanelMalattie">
          <div
            class="protectionBox"
            :class="[
              day.protezione == 'low'
                ? ''
                : day.trattamento_dichiarato == 1
                ? 'declared'
                : day.trattamento_dichiarato == 0
                ? 'notDeclared'
                : ''
            ]"
            v-for="(day, index) in dati.peronospora_heatmap"
            :key="index"
          ></div>
        </div>
        <div class="containerFlex legendPanelMalattie">
          <div
            class="dayBox"
            :class="[day.fill, index == 10 ? 'todayBox' : '']"
            v-for="(day, index) in dati.peronospora_heatmap"
            :key="index"
          >
            <span :class="[day.solo_secondarie == 'true' ? '' : 'invisible']">{{
              2
            }}</span>
          </div>
        </div>

        <div class="bold legendPanelMalattie grigioscuro">{{ labelOI }}</div>
        <div class="containerFlex legendPanelMalattie">
          <div
            class="protectionBox"
            :class="[
              day.protezione == 'low'
                ? ''
                : day.trattamento_dichiarato == 1
                ? 'declared'
                : day.trattamento_dichiarato == 0
                ? 'notDeclared'
                : ''
            ]"
            v-for="(day, index) in dati.oidio_heatmap"
            :key="index"
          ></div>
        </div>
        <div class="containerFlex legendPanelMalattie">
          <div
            class="dayBox"
            :class="[day.fill, index == 10 ? 'todayBox' : '']"
            v-for="(day, index) in dati.oidio_heatmap"
            :key="index"
          >
            <span :class="[day.solo_secondarie == 'true' ? '' : 'invisible']">{{
              2
            }}</span>
          </div>
        </div>

        <div class="bold legendPanelMalattie grigioscuro" v-if="showMAN">
          {{ labelMAN }}
        </div>
        <div class="containerFlex legendPanelMalattie" v-if="showMAN">
          <div
            class="protectionBox"
            :class="[
              day.protezione == 'low'
                ? ''
                : day.trattamento_dichiarato == 1
                ? 'declared'
                : day.trattamento_dichiarato == 0
                ? 'notDeclared'
                : ''
            ]"
            v-for="(day, index) in dati.blackrot_heatmap"
            :key="index"
          ></div>
        </div>
        <div v-if="showMAN" class="containerFlex legendPanelMalattie">
          <div
            class="dayBox"
            :class="[day.fill, index == 10 ? 'todayBox' : '']"
            v-for="(day, index) in dati.blackrot_heatmap"
            :key="index"
          >
            <span :class="[day.solo_secondarie == 'true' ? '' : 'invisible']">{{
              2
            }}</span>
          </div>
        </div>

        <div class="bold legendPanelMalattie grigioscuro">
          {{ labelBOT }} - {{ this.trattamentiBotrite }}
        </div>
        <div class="containerFlex legendPanelMalattie">
          <div
            class="protectionBox"
            :class="[
              day.protezione == 'low'
                ? ''
                : day.trattamento_dichiarato == 1
                ? 'declared'
                : day.trattamento_dichiarato == 0
                ? 'notDeclared'
                : ''
            ]"
            v-for="(day, index) in dati.botrite_heatmap"
            :key="index"
          ></div>
        </div>
        <div class="containerFlex legendPanelMalattie">
          <div
            class="dayBox"
            :class="[day.fill, index == 10 ? 'todayBox' : '']"
            v-for="(day, index) in dati.botrite_heatmap"
            :key="index"
          >
            <span :class="[day.solo_secondarie == 'true' ? '' : 'invisible']">{{
              2
            }}</span>
          </div>
        </div>

        <div class="containerFlex legendPanelMalattie grigioscuro">
          <div
            class="dayBox dateBoxMalattie"
            :style="index == 10 ? 'font-weight: bold' : ''"
            v-for="(day, index) in dati.peronospora_heatmap"
            :key="index"
          >
            {{ convertData(day.x) }}
          </div>
        </div>

        <hr />
        <div class="bold" @click="toggleLegenda">
          <div v-show="displayLegenda" class="grigioscuro">
            {{ labelLegend }}
            <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
          </div>
          <div v-show="!displayLegenda" class="grigioscuro">
            {{ labelLegend }}
            <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
          </div>
        </div>
        <br />

        <div v-if="displayLegenda">
          <div class="bold grigioscuro">{{ labelLegendaMeteo }}</div>
          <div class="containerFlex borderLegend">
            <div class="legendBox">
              <div class="legendItem">
                <div
                  class="declared declaredLegend"
                  style="background-color:red"
                ></div>
              </div>
              <div>{{ labelTempChart }}</div>
            </div>
            <div class="legendBox">
              <div class="legendItem" style="background-color:#003399"></div>
              <div>{{ labelPioggiaChart }}</div>
            </div>
            <div class="legendBox">
              <div class="legendItem" style="background-color:#c5c6c6"></div>
              <div>{{ labelBagnaturaChart }}</div>
            </div>
          </div>

          <div class="bold grigioscuro">{{ livelloRischio }}</div>
          <div class="containerFlex grigioscuro borderLegend">
            <div
              v-for="(item, index) in this.legenda"
              :key="index"
              class="legendBox"
            >
              <div
                class="legendItem"
                :style="`background-color:${item[0]}`"
              ></div>
              <div>
                {{ item[1] }}
              </div>
            </div>
            <div class="legendBox">
              <div class="legendItem">
                <div class="declared declaredLegend"></div>
              </div>
              <div>
                {{ labelProt }}
              </div>
            </div>
            <div class="legendBox">
              <div class="legendItem">
                <div class="tcenter bold">2</div>
              </div>
              <div>
                {{ rischioSecondarie }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-dialog />
      <!-- <div id="waitingPanel" class="waiting">
        <div class="headerWaiting">
          {{ labeWaiting }}
        </div>
        <div class="bodyWaiting">
          {{ labelElab }}
        </div>
        <div class="loaderIcon">
          <img src="/../images/agrishare/horta/ajax-loader.gif" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import VueAnychart from "./VueAnychart.vue";
import queries from "../mixins/queries.js";
import locales from "../mixins/locales.js";

export default {
  mixins: [queries, locales],
  components: { VueAnychart },
  data() {
    return {
      dati: {},
      option: "",
      showMAN: false,
      legenda: [],
      trattamentiBotrite: "",
      rainData: [],
      displayLegenda: false
    };
  },
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      itemInfo: "itemInfo",
      token: "token",
      globalLocale: "globalLocale"
    })
  },
  methods: {
    fetchData() {
      this.queryChart(
        this.token,
        "malattia",
        "",
        this.itemInfo.UPID,
        false,
        "agrigenius",
        "",
        "",
        "",
        "",
        false,
        true,
        "",
        ""
      )
        .then(result => {
          this.dati = result.data?.data?.grafici?.result;

          for (let rain of this.dati.rain_column) {
            if (rain.value == 0) {
              rain.value = "missing";
            }
            this.rainData.push(rain);
          }

          this.optionsChart();
          this.createLegenda();
          this.loaded();
        })
        .catch(error => {
          console.log(error);
        });
    },
    optionsChart() {
      let CombineData = {
        chart: {
          type: "column",
          credits: false,
          padding: [5, 0, 5, 0],
          yScale: {
            //temp line
            minimum: 0,
            maximum: 40,
            ticks: {
              interval: 5
            }
          },
          scales: [
            {},
            {},
            {
              //lw area
              minimum: 0,
              maximum: 24,
              ticks: {
                interval: 4
              }
            },
            {
              //rain column
              minimum: 0,
              maximum: 40,
              ticks: {
                interval: 5
              }
            }
          ],
          xAxes: [
            {
              labels: {
                enabled: false
              }
            }
          ],
          yAxes: [
            {
              title: {
                text: "Temp. (\u00B0C)",
                fontColor: "red",
                fontWeight: "bold",
                enabled: false,
                padding: 0
              }
            },
            {
              title: {
                text:
                  "<span style='color:blue'>Pioggia (mm)</span> / <span style='color:grey'>Bagn. Fogliare (h)</span>",
                fontWeight: "bold",
                useHtml: true,
                enabled: false,
                padding: 0
              },
              labels: {
                enabled: false
              },
              ticks: {
                stroke: "white"
              },
              minorTicks: {
                stroke: "white"
              },
              scale: 3,
              orientation: "right"
            } /*,
            {
              title: {
                text: "LW",
                fontColor: "blue",
                fontWeight: "bold"
              },
              orientation: "right",
              scale: 2,
              enabled: false
            }*/
          ],
          xGrids: [
            {
              enabled: true,
              scale: 1
            }
          ],
          yGrids: [
            {
              enabled: true,
              scale: 0
            }
          ],
          series: [
            {
              seriesType: "area",
              name: "lw",
              data: this.dati.lw_area,
              yScale: 3,
              stroke: {
                color: "#c5c6c6 0.5"
              },
              fill: {
                color: "#c5c6c6 0.5"
              }
            },
            {
              seriesType: "line",
              name: "temp",
              data: this.dati.temp_line,
              stroke: {
                color: "red",
                thickness: 2
              }
            },
            {
              seriesType: "column",
              data: this.rainData,
              yScale: 3,
              name: "rain"
            }
          ]
        }
      };

      this.option = CombineData;
    },
    createLegenda() {
      let items = new Map();
      // this.dati.peronospora_heatmap.forEach(item => {
      //   if (item.heat) items.set(item.fill, this.convertColor(item.fill));
      // });

      // this.dati.oidio_heatmap.forEach(item => {
      //   if (item.heat) items.set(item.fill, this.convertColor(item.fill));
      // });

      // this.dati.botrite_heatmap.forEach(item => {
      //   if (item.heat) items.set(item.fill, this.convertColor(item.fill));
      // });

      // this.dati.blackrot_heatmap.forEach(item => {
      //   if (item.heat) items.set(item.fill, this.convertColor(item.fill));
      // });

      items.set("yellowgreen", this.rischioBasso);
      items.set("gold", this.rischioMedioBasso);
      items.set("orange", this.rischioMedioAlto);
      items.set("orangered", this.rischioAlto);
      items.set("grey", "nd");

      this.legenda = items;

      if (this.dati.botrite_trattamenti.length > 0) {
        this.trattamentiBotrite = this.siTrats;

        let d = null;

        for (const [key, value] in this.dati.botrite_trattamenti) {
          console.log(value);
          if (key == 0) this.trattamentiBotrite += "A";
          else if (key == 1) this.trattamentiBotrite += ", B";
          else if (key == 2) this.trattamentiBotrite += ", C";
          else if (key == 3) this.trattamentiBotrite += ", D";
          d = this.dati.botrite_trattamenti[key];
        }

        this.trattamentiBotrite += ` (${d})`;
      } else {
        this.trattamentiBotrite = this.noTrats;
      }
    },
    convertColor(color) {
      if (color === "yellowgreen") {
        return "Basso";
      }
      if (color === "orangered") {
        return "Alto";
      }
      if (color === "gold") {
        return "Medio-basso";
      }
      if (color === "orange") {
        return "Medio-alto";
      }
      if (color === "grey") {
        return "nd";
      }
    },
    convertData(date) {
      if (!date.length) return "-";
      if (date) return moment(date).format("DD/MM");
      return date;
    },
    loaded() {
      document.getElementById("waitingPanel").style.display = "none";
      document.getElementById("container").style.display = "block";
    },
    toggleLegenda() {
      this.displayLegenda = !this.displayLegenda;
    }
  },
  created() {
    this.showMAN = this.itemInfo.presenzaMarciumeNero;
    this.fetchData();
  }
};
</script>

<style scoped>
.dateBoxMalattie {
  transform: rotate(-90deg);
  transform-origin: 17px 17px;
  height: 35px;
  width: 14px;
  border: none;
}

.declared {
  background-color: blue;
}

.notDeclared {
  background-color: grey;
}

.declaredLegend {
  height: 2px;
  width: 75%;
  margin: 50% auto;
}

.legendPanelMalattie {
  display: flex;
  margin: auto;
  margin-left: 2.25em;
  margin-right: 0.5em;
}

.invisible {
  color: #00000000;
}

.tcenter {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .dateBoxMalattie {
    transform-origin: 24px 12px;
  }
}

@media screen and (min-width: 1024px) {
  .dateBoxMalattie {
    transform-origin: 30px 12px;
    height: 40px;
  }
}
</style>
