<template>
  <compDisclaimer v-if="!this.acceptDisclaimer" :showAcceptBtn="true" />
  <div v-else class="calcHeight grigioscuro padded no-bgImage">
    <!-- <div style="padding: 5px">
      <h3 style="text-align: justify">
        <span id="p1">
          ATTENZIONE: stiamo cambiando la modalità di accesso all'area
          riservata.</span
        >
      </h3>
      <h4 style="text-align: justify">
        <span id="p2">
          Se hai già effettuato la registrazione al nuovo sistema puoi accedere
          direttamente con la tua e-mail come username e la tua nuova password
          qui sotto.
        </span>
      </h4>
      <h4 style="text-align: justify">
        <span id="p3">
          Se non hai ancora effettuato la registrazione al nuovo sistema puoi
          registrarti e accedere dalla nuova pagina di registrazione cliccando
        </span>
        <span @click="setCompToRender('compRegister')">qui.</span>
      </h4>
      <h4 style="text-align: justify">
        <span id="p4">
          Altrimenti, entro il 2 di Settembre, potrai continuare a collegarti
          con la tua vecchia username e password qui sotto.</span
        >
      </h4>
    </div> -->
    <div class="row">
      <div class="col-25">
        <label for="fname">{{ labelUsername }}</label>
      </div>
      <div class="col-75">
        <input
          type="text"
          id="fname"
          name="firstname"
          placeholder="UserName"
          v-model="username"
          autocomplete="off"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <label for="lname">{{ labelPassword }}</label>
      </div>
      <div class="col-75 flex">
        <input
          :type="inputType"
          id="lname"
          name="lastname"
          placeholder="Password"
          v-model="password"
          class="mr10"
          autocomplete="off"
        />
        <a v-if="inputType == 'password'" @click="setInputType('text')">
          <font-awesome-icon
            :icon="['fas', 'eye']"
            class="barsAgego pullRight"
          />
        </a>
        <a v-else @click="setInputType('password')">
          <font-awesome-icon
            :icon="['fas', 'eye-slash']"
            class="barsAgego pullRight"
          />
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <label>{{ labelLang }}</label>
      </div>
      <div class="col-75">
        <select
          class="form-control styledInput"
          v-model="locale"
          @change="changeLocale()"
        >
          <option value="IT">{{ labelIT }}</option>
          <option value="EN">{{ labelEN }}</option>
          <option value="ES">{{ labelES }}</option>
          <option value="PT">{{ labelPT }}</option>
          <option value="TR">{{ labelTR }}</option>
          <option value="FR">{{ labelFR }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <button class="btn btn-primary" @click="submit">{{ labelEnter }}</button>
      <button class="btn btn-primary clearBtn ml20" @click="cleanServiceWorker">
        <i class="refreshAgego icoBtn"></i>
      </button>
    </div>

    <div id="waitingPanel" class="waiting" style="display:none;">
      <div class="headerWaiting">
        {{ labeWaiting }}
      </div>
      <div class="bodyWaiting">
        {{ labelElab }}
      </div>
      <div class="loaderIcon">
        <img src="/../images/agrishare/horta/ajax-loader.gif" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { store } from "../Store/store.js";
import queries from "../mixins/queries.js";
import locales from "../mixins/locales.js";
import cache from "../mixins/cache.js";

import compDisclaimer from "./Disclaimer.vue";

export default {
  data() {
    return {
      username: "",
      password: "",
      locale: "IT",
      inputType: "password"
    };
  },
  components: {
    compDisclaimer
  },
  mixins: [queries, locales, cache],
  computed: {
    ...mapGetters({
      token: "token",
      role: "role",
      reteID: "reteID",
      globalLocale: "globalLocale",
      acceptDisclaimer: "acceptDisclaimer"
    })
  },
  methods: {
    ...mapMutations([
      "setToken",
      "setPassword",
      "setUsername",
      "setCompToRender",
      "setShowNoAziende",
      "setShowNoUp",
      "setItemInfo",
      "setRole",
      "setReteID",
      "setGlobalLocale",
      "setAcceptDisclaimer"
    ]),
    submit() {
      let countAziende = 0;
      let countUps = 0;
      if (this.username && this.password) {
        document.getElementById("waitingPanel").style.display = "block";
        this.retrieveToken(this.username.toLowerCase(), this.password)
          .then(response => {
            return response.data?.[0].token;
          })
          .then(token => {
            if (token.Issuer == "HORTA") {
              //gigya
              this.setToken(token.Bearer);
              this.setCookies("gigya", null);
              return this.chiaviServizi(token.Bearer);
            } else {
              this.hermes(token.Bearer);
              return this.retrieveAuthKey(this.token);
            }
          })
          .then(result => {
            const isLegacy = result.data?.data?.authKeys != undefined;
            const isV2 = result.data?.esito != undefined;
            this.saveAuth(this.username.toLowerCase(), this.password);

            if (isLegacy) {
              return this.retrieveMasterAziende(this.token);
            } else if (isV2) {
              //
              const esito = result.data?.esito;
              if (esito == 1) {
                throw new Error("User role is not set!");
              } else {
                return this.retrieveMasterAziende(this.token);
              }
            } else {
              throw new Error("no user_id&password neither horta_token found!");
            }
          })
          .then(aziende => {
            const listAziende = aziende.data?.data?.masterAziende;
            countAziende = listAziende.length;

            return this.retrieveMasterUp(this.token, this.globalLocale, true);
          })
          .then(ups => {
            const listUps = ups.data?.data?.masterUP;
            countUps = listUps.length;

            if (countUps == 0) {
              return this.retrieveMasterUp(
                this.token,
                this.globalLocale,
                false
              );
            } else return ups;
          })
          .then(ups => {
            const listUps = ups.data?.data?.masterUP;
            countUps = listUps.length;

            this.moveTo(countAziende, countUps);
          })
          .catch(error => {
            console.log(error);
            const err401 = "Request failed with status code 401";
            const errRole = "User role is not set!";
            if (error.toString().indexOf(err401) > -1) {
              this.setCompToRender("compErrorLogin");
              return;
            }
            if (error.toString().indexOf(errRole) > -1) {
              this.setCompToRender("compErrorNoService");
              return;
            }
            this.setCompToRender("compError");
          });
      } else {
        let message = {
          title: this.labelWarn,
          body: this.warnLogin
        };
        let options = {
          okText: this.labelCloseBtn
        };
        this.$dialog.alert(message, options);
      }
    },
    moveTo(countAziende, countUps) {
      if (countAziende == 0) {
        this.setItemInfo("");
        this.setShowNoAziende(true);
        this.setCompToRender("compHome");

        return;
      }

      if (countUps == 0) {
        this.setShowNoUp(true);
        this.setCompToRender("compHome");

        return;
      }

      this.setUsername(this.username);
      this.setPassword(this.password);
      this.setCompToRender("compUp");
    },
    changeLocale() {
      this.setGlobalLocale(this.locale);
      document.title = this.headerTitle;
    },
    hermes(hermesBearer) {
      const splitted = hermesBearer.split(".");

      let bearer = "";
      const ls = splitted.length;
      for (let i = 0; i < ls - 1; ++i) {
        bearer = bearer + "." + splitted[i];
      }
      bearer = bearer.substring(1);
      const last = splitted[ls - 1];
      const reverse = last
        .split("")
        .reverse()
        .join("");
      const uppercase = reverse.toUpperCase();

      this.setToken(bearer, uppercase);
      this.setCookies("legacy", uppercase);
    },
    setInputType(type) {
      this.inputType = type;
    },
    cleanServiceWorker() {
      this.loading();
      if (typeof navigator.serviceWorker !== "undefined") {
        navigator.serviceWorker
          .getRegistrations()
          .then(registrations => {
            registrations.forEach(registration => {
              registration.unregister();
            });
          })
          .then(() => {
            this.$router.go();
          });
      }
      setTimeout(this.loaded, 2500);
    },
    loading() {
      document.getElementById("waitingPanel").style.display = "block";
    },
    loaded() {
      document.getElementById("waitingPanel").style.display = "none";
    },
    setCookies(type, pwd) {
      let domain = store.state.domain;
      const hostname = window.location.hostname;
      if (hostname.indexOf("horta-stg") > -1) {
        domain = "horta-stg.basf.com";
      }
      if (type == "gigya") {
        document.cookie = `HORTA_TOKEN=${this.token}; domain=${domain}; path=/`;
      } else {
        document.cookie = `GIAS_USERID=${this.username}; domain=${domain}; path=/`;
        document.cookie = `GIAS_PASSWORD=${pwd}; domain=${domain}; path=/`;
        document.cookie = `SERV_ID=${store.state.servIdIn}; domain=${domain}; path=/api`;
      }
      document.cookie = `GIAS_LANG=${this.globalLocale}; domain=${domain}; path=/`;
    }
  },
  created() {
    if (document.cookie.indexOf("ACCEPTED_DISCLAIMER_AGEGO=true") > -1) {
      this.setAcceptDisclaimer(true);
    }
    if (this.acceptDisclaimer) {
      this.getToken()
        .then(response => {
          this.username = response?.username.toLowerCase();
          this.password = response?.password;
          this.locale = this.globalLocale;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.bgImage {
  background-image: url("https://cloudtest.horta-srl.it/horta_menu/resources/images/flags/login_age_vite.jpg");
  background-position: center;
  background-size: cover;
}

.padded {
  padding: 0 25px;
}

/* Style inputs, select elements and textareas */
input[type="text"],
input[type="password"],
select,
textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

/* Style the label to display next to the inputs */
label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

/* Style the submit button */
input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

/* Style the container */
.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

/* Floating column for labels: 25% width */
.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

/* Floating column for inputs: 75% width */
.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

.btn-primary {
  margin-top: 20px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}

.clearBtn {
  padding: 0;
  vertical-align: bottom;
  height: 26px;
  width: 50px;
}

.icoBtn {
  width: 26px;
}
</style>
