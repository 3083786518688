<template>
  <div>
    <footer>
      <div style="float:left;">
        <span>Powered by Horta S.r.l.</span>
      </div>

      <div style="float:right;">
        <a @click="go2disclaimer()">Disclaimer</a>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  methods: {
    ...mapMutations(["setCompToRender", "setCurrentSectionDisclaimer"]),
    go2disclaimer() {
      this.setCurrentSectionDisclaimer(this.compToRender);
      this.setCompToRender("compDisclaimer");
    }
  },
  computed: {
    ...mapGetters({
      compToRender: "compToRender"
    })
  }
};
</script>

<style>
footer {
  font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
  background: #65ac1e;
  border-top: 4px solid #6f6f6e;
  box-sizing: border-box;
  padding: 10px;
  color: #ffffff;
  margin: auto;
  overflow: hidden;
}
</style>
