<template>
  <div>
    <app-header v-if="showHeader" />
    <div
      id="main"
      class="container"
      :class="showHeader ? '' : 'containerNoHeader'"
    >
      <div
        id="toolbarTop"
        class="shadowBox"
        v-if="
          compToRender !== 'compHome' &&
            compToRender !== 'compLogin' &&
            compToRender !== 'compLoginGigya' &&
            compToRender !== 'compDisclaimer' &&
            compToRender !== 'compError' &&
            compToRender !== 'compErrorLogin' &&
            compToRender !== 'compPwa' &&
            compToRender !== 'compRegister' &&
            compToRender !== 'compErrorNoService'
        "
      >
        <div
          id="btnsUp"
          class="flex"
          v-if="compToRender.indexOf('Up') > -1 && compToRender !== 'compUp'"
        >
          <div
            class="icoToolbar indietroAgego"
            :class="itemInfo.UPID ? '' : 'aloneIco'"
            @click="onChangeTabset('compUp')"
          ></div>
          <div
            class="icoToolbar"
            :class="
              compToRender === 'compMeteoUp'
                ? 'active meteoWhiteAgego'
                : 'meteoAgego'
            "
            v-if="itemInfo.UPID"
            @click="onChangeTabset('compMeteoUp')"
          ></div>
          <div
            class="icoToolbar"
            :class="
              compToRender === 'compFenoUp'
                ? 'active fenologiaWhiteAgego'
                : 'fenologiaAgego'
            "
            v-if="itemInfo.UPID"
            @click="onChangeTabset('compFenoUp')"
          ></div>
          <div
            class="icoToolbar"
            :class="
              compToRender === 'compMalattieUp'
                ? 'active malattieWhiteAgego'
                : 'malattieAgego'
            "
            v-if="itemInfo.UPID"
            @click="onChangeTabset('compMalattieUp')"
          ></div>
          <div
            class="icoToolbar"
            :class="
              compToRender === 'compInsettiUp'
                ? 'active insettiWhiteAgego'
                : 'insettiAgego'
            "
            v-if="itemInfo.UPID"
            @click="onChangeTabset('compInsettiUp')"
          ></div>
          <div
            class="icoToolbar"
            :class="
              compToRender === 'compFitoUp'
                ? 'active fitoWhiteAgego2'
                : 'fitoAgego2'
            "
            v-if="itemInfo.UPID"
            @click="onChangeTabset('compFitoUp')"
          ></div>
          <div
            class="icoToolbar"
            :class="
              compToRender === 'compProtUp'
                ? 'active protezioneWhiteAgego'
                : ' protezioneAgego'
            "
            v-if="itemInfo.UPID"
            @click="onChangeTabset('compProtUp')"
          ></div>
          <div
            class="icoToolbar"
            :class="
              compToRender === 'compRocUp' ? 'active rocWhiteAgego' : 'rocAgego'
            "
            v-if="itemInfo.UPID"
            @click="onChangeTabset('compRocUp')"
          ></div>
        </div>

        <div id="sectionSelect" class="flex" v-if="compToRender === 'compUp'">
          <div
            class="icoMainToolbar aziendeAgego"
            @click="onChange('compAziende')"
          ></div>
          <div
            class="icoMainToolbar addAgego"
            @click="onChange('compAddUp')"
          ></div>
          <div
            class="icoMainToolbar filterAgego"
            @click="onChange('compFilterUp')"
          ></div>
          <div
            class="icoMainToolbar cleanfilterAgego"
            @click="onChange('cleanFiltri')"
            v-if="filterOn"
          ></div>
          <div
            class="icoMainToolbar attiveAgego"
            @click="onChange('showUpAttive')"
            v-if="!isActive"
          ></div>
          <div
            class="icoMainToolbar scaduteAgego"
            @click="onChange('showUpScadute')"
            v-if="isActive"
          ></div>
        </div>

        <div
          id="sectionSelectAziende"
          class="flex"
          v-if="compToRender.indexOf('Aziende') > -1"
        >
          <div
            class="icoAzToolbar elencoUpAgego"
            @click="onChange('compUp')"
          ></div>

          <div
            class="icoAzToolbar indietroAgego"
            @click="onChange('compAziende')"
            v-if="compToRender !== 'compAziende'"
          ></div>

          <div
            class="icoAzToolbar addAgego"
            @click="onChange('compAddAziende')"
            v-if="
              compToRender !== 'compAddAziende' &&
                compToRender !== 'compProdottiAziende' &&
                compToRender !== 'compFilterAziende'
            "
          ></div>

          <div
            class="icoAzToolbar filterAgego"
            @click="onChange('compFilterAziende')"
            v-if="
              compToRender !== 'compAddAziende' &&
                compToRender !== 'compProdottiAziende' &&
                compToRender !== 'compFilterAziende'
            "
          ></div>

          <div
            class="icoAzToolbar cleanfilterAgego"
            @click="onChange('cleanFiltriAziende')"
            v-if="filterOn"
          ></div>
        </div>
      </div>

      <div :id="this.compToRender">
        <component :is="this.compToRender"></component>
      </div>
    </div>
    <app-footer
      v-if="
        compToRender !== 'compLogin' &&
          compToRender !== 'compLoginGigya' &&
          compToRender !== 'compRegister'
      "
    />
  </div>
</template>

<script>
import compUp from "./Components/Up/Users.vue";
import compAddUp from "./Components/Up/AddUsers.vue";
import compViewUp from "./Components/Up/ViewUser.vue";
import compFilterUp from "./Components/Up/FilterUsers.vue";

import compMeteoUp from "./Components/Meteo.vue";
import compFenoUp from "./Components/Fenologia.vue";
import compMalattieUp from "./Components/Malattie.vue";
import compInsettiUp from "./Components/Insetti.vue";
import compFitoUp from "./Components/Fito.vue";
import compProtUp from "./Components/Protezione.vue";
import compRocUp from "./Components/Roc.vue";

import compEditFeno from "./Components/Fenologia/EditFenologia.vue";
import compTabFeno from "./Components/Fenologia/FasiFen.vue";

import compAziende from "./Components/Aziende/Aziende.vue";
import compAddAziende from "./Components/Aziende/AddAziende.vue";
import compViewAziende from "./Components/Aziende/ViewAziende.vue";
import compFilterAziende from "./Components/Aziende/FilterAziende.vue";
import compProdottiAziende from "./Components/Aziende/ProdottiPreferiti.vue";

import compMap from "./Components/Map.vue";
import compHome from "./Components/Home.vue";
import compLogin from "./Components/Login.vue";
import compLoginGigya from "./Components/LoginGigya.vue";
import compManutenzione from "./Components/Manutenzione.vue";
import compPwa from "./Components/Pwa.vue";
import compErrorLogin from "./Components/ErrorLogin.vue";
import compErrorNoService from "./Components/ErrorNoService.vue";
import compError from "./Components/Error.vue";
import compDisclaimer from "./Components/Disclaimer.vue";
import compRegister from "./Components/Register.vue";
import compTermini from "./Components/Termini.vue";
import compMarketing from "./Components/Marketing.vue";

import { mapGetters, mapMutations } from "vuex";
import queries from "./mixins/queries.js";
import locales from "./mixins/locales.js";

export default {
  data() {
    return {
      section: "",
      toolbar: false
    };
  },
  mixins: [queries, locales],
  computed: {
    ...mapGetters({
      showHeader: "showHeader",
      compToRender: "compToRender",
      example: "example",
      itemInfo: "itemInfo",
      filterOn: "filterOn",
      currentSection: "currentSection",
      maxPage: "maxPage",
      currentPage: "currentPage",
      totalItem: "totalItem",
      limit: "limit",
      maxUp: "maxUp",
      showNoAziende: "showNoAziende",
      showNoUp: "showNoUp",
      totalList: "totalList",
      username: "username",
      password: "password",
      token: "token",
      isActive: "isActive",
      isDisabledAvanti: "isDisabledAvanti",
      isDisabledIndietro: "isDisabledIndietro",
      isDisabledPrima: "isDisabledPrima",
      isDisabledUltima: "isDisabledUltima",
      globalLocale: "globalLocale"
    })
  },
  methods: {
    ...mapMutations([
      "setCompToRender",
      "setShowHeader",
      "setExample",
      "toggleFilterOn",
      "setCurrentSection",
      "setItemInfo",
      "setTotalItem",
      "setMaxPage",
      "setCurrentPage",
      "setGeoLat",
      "setGeoLng",
      "setTotalList",
      "setItemList",
      "setToken",
      "setIsActive",
      "setShowMsg",
      "setIsDisabledAvanti",
      "setIsDisabledIndietro",
      "setIsDisabledPrima",
      "setIsDisabledUltima",
      "setGlobalLocale"
    ]),
    onChangeTabset(section) {
      const checkFreeze =
        section === "compFenoUp" ||
        section === "compMalattieUp" ||
        section === "compInsettiUp" ||
        section === "compProtUp" ||
        section === "compRocUp";
      if (checkFreeze && !this.itemInfo.freezed) {
        return;
      }
      const selectedAction = section;
      this.setCompToRender(selectedAction);
      this.section = selectedAction;
    },
    onChange(section) {
      this.closeMainSidebar();

      const selectedAction = section;
      if (selectedAction) {
        this.resetPaginator();
        this.setShowMsg(false);
        const showUpAttive = selectedAction === "showUpAttive";
        const showUpScadute = selectedAction === "showUpScadute";
        const isCleanFiltriUp = selectedAction === "cleanFiltri";
        const isCleanFiltriAziende = selectedAction === "cleanFiltriAziende";
        const isNewUp = selectedAction === "compAddUp";
        const isNewAzienda = selectedAction === "compAddAziende";

        const isCompValid =
          !showUpAttive &&
          !showUpScadute &&
          !isCleanFiltriUp &&
          !isCleanFiltriAziende;

        if (showUpAttive) {
          this.setIsActive(true);
          this.fetchData();
        }
        if (showUpScadute) {
          this.setIsActive(false);
          this.fetchData();
        }
        if (isCleanFiltriUp) {
          this.setExample("");
          this.toggleFilterOn(false);
          this.fetchData();
        }
        if (isCleanFiltriAziende) {
          this.setExample("");
          this.toggleFilterOn(false);
          this.fetchDataAziende();
        }
        if (isNewUp) {
          this.setCompToRender(selectedAction);
          this.section = selectedAction;
        }
        if (!this.itemInfo || isNewUp || isNewAzienda) {
          this.setShowMsg(false);
          this.setItemInfo("");
          this.setExample("");
          this.setGeoLat("");
          this.setGeoLng("");
        }

        if (isCompValid) {
          this.setCompToRender(selectedAction);
          this.section = selectedAction;
        }
      }
    },
    cleanFilter() {
      this.toggleFilterOn(false);
      this.setExample("");
    },
    fetchData() {
      this.loading();
      this.setTotalList([]);
      this.setItemList([]);

      if (!this.isExampleEnabled()) {
        this.retrieveMasterUp(this.token, this.globalLocale, this.isActive)
          .then(result => {
            const listUp = result.data.data.masterUP;
            const countUp = listUp.length;
            this.setTotalItem(countUp);
            if (countUp > 0) {
              this.setMaxPage(Math.ceil(this.totalItem / this.limit));
            } else this.setMaxPage(1);

            this.setTotalList(listUp);
            this.slicingList(this.totalList);
            this.checkDisabled();
            this.loaded();
            //
            if (this.currentPage > this.maxPage) {
              this.setCurrentPage(1);
              this.slicingList(this.totalList);
              this.fetchData(this.username, this.password);
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        return this.retrieveMasterUpFiltred(
          this.token,
          this.example,
          this.isActive,
          this.globalLocale
        )
          .then(result => {
            const listUp = result.data.data.masterUP;
            const countUp = listUp.length;
            this.setTotalItem(countUp);
            if (countUp > 0) {
              this.setMaxPage(Math.ceil(this.totalItem / this.limit));
            } else this.setMaxPage(1);

            this.setTotalList(listUp);
            this.slicingList(this.totalList);
            this.checkDisabled();
            this.loaded();
            //
            if (this.currentPage > this.maxPage) {
              this.setCurrentPage(1);
              this.slicingList(this.totalList);
              this.fetchData(this.username, this.password);
            }
          })
          .catch(error => {
            console.log(error);
            if (
              error.toString().indexOf("Request failed with status code 401") >
              -1
            ) {
              this.setCompToRender("compErrorLogin");
            }
          });
      }
    },
    fetchDataAziende() {
      this.retrieveMasterAziende(this.token)
        .then(result => {
          const listAziende = result.data?.data?.masterAziende;
          const countAziende = listAziende.length;
          this.setTotalItem(countAziende);
          if (countAziende > 0) {
            this.setMaxPage(Math.ceil(this.totalItem / this.limit));
          } else this.setMaxPage(1);

          this.setTotalList(listAziende);
          this.slicingList(this.totalList);
          this.checkDisabled();

          if (this.currentPage > this.maxPage) {
            this.setCurrentPage(1);
            this.slicingList(this.listaAziende);
            this.fetchData();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    checkDisabled() {
      this.setIsDisabledAvanti(this.currentPage >= this.maxPage);
      this.setIsDisabledIndietro(this.currentPage <= 1);
      this.setIsDisabledPrima(this.currentPage <= 1);
      this.setIsDisabledUltima(this.currentPage >= this.maxPage);
    },
    slicingList(list) {
      const slicedList = list.slice(
        this.limit * (this.currentPage - 1),
        this.limit * this.currentPage
      );
      this.setItemList(slicedList);
    },
    resetPaginator() {
      this.setCurrentPage(1);
      this.setMaxPage(1);
      this.setItemList([]);
      this.setTotalList([]);
    },
    handleTouchStart(evt) {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
    },
    handleTouchMove(evt) {
      const widthWindow = window.width;
      if (!this.xDown || !this.yDown || widthWindow > 1024) {
        return;
      }

      const xUp = evt.touches[0].clientX;
      const yUp = evt.touches[0].clientY;

      const xDiff = this.xDown - xUp;
      const yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          /* left swipe */
          this.closeMainSidebar();
        } else {
          /* right swipe */
        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
          this.closeMainSidebar();
        } else {
          /* down swipe */
        }
      }
      /* reset values */
      this.xDown = null;
      this.yDown = null;
    },
    closeall() {
      this.closeAllSide();
      this.closeAllFunz();
      this.closeMainSidebar();
    },
    closeAllSide() {
      const x = document.querySelectorAll('[id^="mySidebar_"]');
      for (const value of x) {
        value.style.width = "0";
      }
    },
    closeAllFunz() {
      const y = document.querySelectorAll('[id^="funzSidebar"]');
      for (const value of y) {
        value.style.width = "0";
      }
    },
    openMainSidebar() {
      this.closeall();
      document.getElementById("mainSideBar").style.width = "250px";
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width >= 768) {
        document.getElementById("main").style.marginLeft = "250px";
        document.getElementById("main").style.marginRight = "0px";
      }
    },
    closeMainSidebar() {
      // document.getElementById("mainSideBar").style.width = "0";
      // var width =
      //   window.innerWidth ||
      //   document.documentElement.clientWidth ||
      //   document.body.clientWidth;
      // if (width >= 768) document.getElementById("main").style.marginLeft = "0";
    },
    loading() {
      document.getElementById("waitingPanel").style.display = "block";
    },
    loaded() {
      document.getElementById("waitingPanel").style.display = "none";
    },
    isExampleEnabled() {
      return (
        this.example.UPDes ||
        this.example.IDCli ||
        this.example.pIVAAzi ||
        this.example.nazISO ||
        this.example.regione ||
        this.example.provincia ||
        this.example.quadranteID ||
        this.example.sistemaColturaleID ||
        this.example.varCod ||
        this.example.doseInoculoID ||
        this.example.presenzaMarciumeNero
      );
    }
  },
  components: {
    compUp,
    compAddUp,
    compViewUp,
    compFilterUp,
    compMeteoUp,
    compFenoUp,
    compMalattieUp,
    compInsettiUp,
    compFitoUp,
    compProtUp,
    compRocUp,
    compAziende,
    compAddAziende,
    compViewAziende,
    compFilterAziende,
    compProdottiAziende,
    compMap,
    compHome,
    compEditFeno,
    compTabFeno,
    compLogin,
    compErrorLogin,
    compError,
    compPwa,
    compDisclaimer,
    compManutenzione,
    compErrorNoService,
    compRegister,
    compTermini,
    compMarketing,
    compLoginGigya
  },
  created() {
    console.log("created");
    document.addEventListener("touchstart", this.handleTouchStart, false);
    document.addEventListener("touchmove", this.handleTouchMove, false);
    document.title = this.headerTitle;
  },
  mounted() {
    console.log("mounted");
    if (this.$route.query.toolbar) {
      const toolbar = this.$route.query.toolbar;
      if (toolbar == "1") {
        this.toolbar = true;
      } else this.toolbar = false;
    }
    if (this.$route.query.showHeader == "false") {
      this.setShowHeader(false);
    }
    if (this.$route.query.compToRender) {
      this.setCompToRender(this.$route.query.compToRender);
    }
    if (this.$route.query.up) {
      const upId = this.$route.query.up;
      this.itemInfo.UPID = upId;
    }
    if (this.$route.query.freezed) {
      const freezed = this.$route.query.freezed;
      if (freezed == "1") {
        this.itemInfo.freezed = true;
      } else this.itemInfo.freezed = false;
    }
    if (this.$route.query.lang) {
      this.setGlobalLocale(this.$route.query.lang);
    }

    const script = document.createElement("script");
    script.src =
      "https://cdns.eu1.gigya.com/js/gigya.js?apikey=4_f9w4g6t0mWIOoSWZXte5RA";

    document.head.appendChild(script);
  }
};
</script>

<style>
@import "./assets/styles/icons.css";
@import "./assets/styles/app.css";
@import "./assets/styles/media.css";
</style>
