<template>
  <div class="marginBox grigioscuro">
    <h2>{{ labelTitleAZearch }}</h2>

    <div class="adduser_item">
      <label>{{ labelUtente }}</label>
      <select
        class="form-control styledInput styledInput"
        v-model="example.IDCli"
      >
        <option
          v-for="(utente, index) in utenti"
          :key="index"
          :value="utente.IDCli"
        >
          {{ utente.rgsCliente }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelRGS }}</label>
      <input
        class="form-control styledInput styledInput"
        type="text"
        v-model="example.rgsAzienda"
      />
    </div>

    <div class="adduser_item">
      <label>{{ labelPiva }}</label>
      <input
        class="form-control styledInput styledInput"
        type="text"
        v-model="example.pIVAAzi"
        maxlength="11"
      />
    </div>

    <div class="adduser_item">
      <label>{{ labelNazione }}</label>
      <select
        class="form-control styledInput styledInput"
        v-model="example.nazISO"
        @change="listRegioni()"
      >
        <option></option>
        <option
          v-for="(nazione, index) in nazioni"
          :key="index"
          :value="nazione.nazISO"
        >
          {{ nazione.nazDes }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelRegione }}</label>
      <select
        class="form-control styledInput styledInput"
        v-model="example.regione"
        @change="listProvince()"
      >
        <option
          v-for="(regione, index) in regioni"
          :key="index"
          :value="toIDRegione(regione)"
          :disabled="regione.disabled"
        >
          {{ toStringRegione(regione) }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelProvincia }}</label>
      <select
        class="form-control styledInput styledInput"
        v-model="example.provincia"
        @change="listComuni()"
      >
        <option
          v-for="(provincia, index) in province"
          :key="index"
          :value="toIDProvincia(provincia)"
          :disabled="provincia.disabled"
        >
          {{ toStringProvincia(provincia) }}
        </option>
      </select>
    </div>

    <div class="adduser_item" v-if="example.nazISO == 'IT'">
      <label>{{ labelComune }}</label>
      <select
        class="form-control styledInput styledInput"
        v-model="example.comIst"
      >
        <option
          v-for="(comune, index) in comuni"
          :key="index"
          :value="comune.comIst"
          :disabled="comune.disabled"
        >
          {{ comune.comDes }}
        </option>
      </select>
    </div>

    <p v-if="showMsg" class="info">
      <span>{{ textMsg }}</span>
    </p>
    <div v-if="errors.length" class="error">
      <b>{{ labelErrorMsg }}:</b>
      <ul>
        <li v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </div>

    <hr />
    <button class="btn btn-primary" @click="submit">{{ labelCerca }}</button>
    <button class="btn btn-default" @click="annulla">{{ labelAnnulla }}</button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import queries from "../../mixins/queries.js";
import locales from "../../mixins/locales.js";

export default {
  data() {
    return {
      showMsg: false,
      textMsg: "",
      errors: [],
      utenti: [],
      nazioni: [],
      regioni: [],
      province: [],
      comuni: [],
      isEdit: false,
      example: {
        rgsAzienda: "",
        IDCli: "",
        pIVAAzi: "",
        nazISO: "",
        regIst: "",
        proIst: "",
        comIst: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      token: "token",
      globalLocale: "globalLocale"
    })
  },
  mixins: [queries, locales],
  methods: {
    ...mapMutations(["setCompToRender", "setExample", "toggleFilterOn"]),
    submit() {
      this.setExample(this.example);
      this.setCompToRender("compAziende");
      this.toggleFilterOn(true);
    },
    fillDropDown() {
      this.retrieveAziendeDropDownInfo(this.token)
        .then(response => {
          return response.data.data;
        })
        .then(data => {
          const listaUtenti = data.formNuovaAzienda?.listaUtenti;
          this.utenti = listaUtenti;
          const listaNazioni = data.formNuovaAzienda?.listaNazioni;
          this.nazioni = listaNazioni;
        })
        .catch(error => {
          console.log(error);
        });
    },
    listRegioni() {
      this.regioni = [];
      if (this.example.nazISO) {
        const nazIso = this.example.nazISO;
        this.retrieveRegioni(
          this.token,
          nazIso == "IT" ? "" : nazIso,
          nazIso == "IT" ? "regDes" : "nuts2Des"
        )
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            let listaRegioniBy = [];
            if (nazIso == "IT") {
              listaRegioniBy = data.formNuovaUP?.listaRegioni?.regioni;
            } else {
              listaRegioniBy = data.formNuovaUP?.listaRegioni?.nutsLevel2;
            }

            this.regioni = listaRegioniBy;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.regioni.push(
          {
            regDes: "",
            disabled: true
          },
          {
            regDes: this.noCountry,
            disabled: true
          }
        );
      }
    },
    toStringRegione(regione) {
      if (regione.regDes) {
        return regione.regDes;
      } else if (regione.nuts2Des) {
        return regione.nuts2Des;
      }
    },
    toIDRegione(regione) {
      if (regione.regIst) {
        return regione.regIst;
      } else if (regione.nuts2Code) {
        return regione.nuts2Code;
      }
    },
    listProvince() {
      this.province = [];
      const nazIso = this.example.nazISO;
      if (this.example.regione) {
        this.retrieveProvince(
          this.token,
          nazIso == "IT" ? this.example.regione : "",
          nazIso == "IT" ? "" : this.example.regione,
          nazIso == "IT" ? "proDes" : "nuts3Des"
        )
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            let listaProvinceBy = {};
            if (nazIso == "IT") {
              listaProvinceBy = data.formNuovaUP?.listaProvince?.province;
            } else {
              listaProvinceBy = data.formNuovaUP?.listaProvince?.nutsLevel3;
            }
            this.province = listaProvinceBy;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.province.push(
          {
            proDes: "",
            disabled: true
          },
          {
            proDes: this.noRegione,
            disabled: true
          }
        );
      }
    },
    toStringProvincia(provincia) {
      if (provincia.proDes) {
        return provincia.proDes;
      } else if (provincia.nuts3Des) {
        return provincia.nuts3Des;
      }
    },
    toIDProvincia(provincia) {
      if (provincia.proIst) {
        return provincia.proIst;
      } else if (provincia.nuts3Code) {
        return provincia.nuts3Code;
      }
    },
    listComuni() {
      this.comuni = [];
      if (this.example.provincia) {
        this.retrieveComuni(this.token, this.example.provincia, "")
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            const listaComuniBy = data.formNuovaUP?.listaComuni;
            this.comuni = listaComuniBy;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.comuni.push(
          {
            comDes: "",
            disabled: true
          },
          {
            comDes: this.noProvincia,
            disabled: true
          }
        );
      }
    },
    cleanForm() {
      this.example = {
        rgsAzienda: "",
        IDCli: "",
        pIVAAzi: "",
        nazISO: "",
        proIst: "",
        comIst: ""
      };
    },
    annulla() {
      this.setCompToRender("compAziende");
    }
  },
  created() {
    this.fillDropDown();
    this.listRegioni();
    this.listProvince();
    this.listComuni();
  }
};
</script>

<style scoped>
.error {
  color: red;
}

.info {
  font-weight: bold;
}

select {
  width: 147px;
}
</style>
