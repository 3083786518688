<template>
  <div class="marginBox grigioscuro">
    <h2>{{ labelTitleAZView }} - {{ itemInfo.rgsAzienda }}</h2>

    <div class="adduser_item">
      <label>{{ labelUtente }}</label>
      <span>{{ itemInfo.rgsCliente }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelRGS }}</label>
      <span>{{ itemInfo.rgsAzienda }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelPiva }}</label>
      <span>{{ itemInfo.pIVAAzi }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelCF }}</label>
      <span>{{ itemInfo.codiceFiscale }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelNazione }}</label>
      <span>{{ itemInfo.nazDes }}</span>
    </div>

    <div class="adduser_item" v-if="itemInfo.nazISO != 'IT'">
      <label>{{ labelRegione }}</label>
      <span>{{ itemInfo.nuts2Des }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelProvincia }}</label>
      <span v-if="itemInfo.nazISO == 'IT'">{{ itemInfo.proDes }}</span>
      <span v-else>{{ itemInfo.nuts3Des }}</span>
    </div>

    <div class="adduser_item" v-if="itemInfo.nazISO == 'IT'">
      <label>{{ labelComune }}</label>
      <span>{{ itemInfo.comDes }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelIndirizzo }}</label>
      <span>{{ itemInfo.indirizzo }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelCAP }}</label>
      <span>{{ itemInfo.CAP }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelEmail }}</label>
      <span>{{ itemInfo.eMail }}</span>
    </div>

    <div class="adduser_item">
      <label>{{ labelListProdotti }}</label>
      <table>
        <tr>
          <th class="up_item_header">{{ labelNomeProdotti }}</th>
          <th class="up_item_header">{{ labelDittaProdotti }}</th>
          <th class="up_item_header">{{ labelAvversitaProdotti }}</th>
        </tr>
        <tr v-if="prodottiPreferitiList.length == 0">
          <td colspan="4" class="noItems">
            <span>Nessun prodotto presente</span>
          </td>
        </tr>
        <tr
          class="up_item_row"
          :class="index % 2 == 0 ? 'evenRow' : 'oddRow'"
          v-for="(prodotto, index) in prodottiPreferitiList"
          :key="index"
        >
          <td>{{ prodotto.nome }}</td>
          <td>{{ prodotto.distributoreDes }}</td>
          <td>{{ prodotto.avversitaDes }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import queries from "../../mixins/queries.js";
import locales from "../../mixins/locales.js";

export default {
  data() {
    return {
      prodottiPreferitiList: []
    };
  },
  mixins: [queries, locales],
  computed: {
    ...mapGetters({
      itemInfo: "itemInfo",
      token: "token",
      globalLocale: "globalLocale"
    })
  },
  methods: {
    listProdottixAziende() {
      this.retrieveMasterProdottiPreferitiAzienda(
        this.token,
        this.globalLocale,
        this.itemInfo.IDCli,
        this.itemInfo.pIVAAzi
      )
        .then(result => {
          this.prodottiPreferitiList =
            result.data?.data?.masterProdottiPreferiti;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    this.listProdottixAziende();
  },
  mounted() {}
};
</script>

<style scoped>
.ico {
  width: 30px;
}
</style>
