<template>
  <div class="marginBox grigioscuro">
    <h2 v-if="isEdit && !showMap">
      {{ labelTitleEdit }} - {{ formdata.UPDes }}
    </h2>
    <h2 v-if="!isEdit && !showMap">{{ labelTitleNew }}</h2>
    <div v-if="showMap" class="shadowBox subTitle">
      <button class="btn btn-primary" @click="openHelpMap" style="float:right;">
        Help
      </button>
      <button
        class="btn btn-default"
        @click="closeMap"
        style="float:right; margin-right: 2px;"
      >
        Annulla
      </button>
      <button
        v-if="markers.length > 0"
        @click="saveAndclose"
        class="btn btn-primary"
        style="float:right; margin-right: 2px;"
      >
        Salva e continua
      </button>
      <span class="subTitleText">{{ titleMap }}</span>
    </div>

    <div v-if="this.showMsg" class="alert alert-danger">
      <span>{{ this.insertMsg }}</span>
    </div>

    <div v-if="errors.length" class="alert alert-danger">
      <b>{{ labelErrorMsg }}</b>
      <ul>
        <li v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </div>

    <div id="formUp" v-if="!showMap">
      <div class="adduser_item">
        <label>{{ labelUtente }}</label>
        <!-- <select
          class="form-control styledInput"
          v-model="formdata.IDCli"
          @change="listAziende()"
        >
          <option
            v-for="(utente, index) in utenti"
            :key="index"
            :value="utente.IDCli"
          >
            {{ utente.rgsCliente }}
          </option>
        </select> -->
        <v-select
          label="rgsCliente"
          :clearable="false"
          class="searchSelect"
          v-model="formdata.IDCli"
          :options="utenti"
          :reduce="c => c.IDCli"
          @input="listAziende()"
        >
          <div slot="no-options">{{ noItems }}</div>
        </v-select>
      </div>

      <div class="adduser_item">
        <label>{{ labelAzienda }}</label>
        <select class="form-control styledInput" v-model="formdata.pIVAAzi">
          <option
            v-for="(azienda, index) in aziende"
            :key="index"
            :value="azienda.pIVAAzi"
            :disabled="azienda.disabled"
          >
            {{ azienda.rgsAzienda }}
          </option>
        </select>
      </div>

      <div class="adduser_item">
        <label>{{ labelDescrizione }}</label>
        <input
          class="form-control styledInput"
          type="text"
          v-model="formdata.UPDes"
        />
      </div>

      <div class="adduser_item">
        <label>{{ labelSuperficie }}</label>
        <input
          class="form-control styledInput"
          type="text"
          v-model="formdata.UPSup"
        />
      </div>

      <div class="adduser_item">
        <label>{{ labelNazione }}</label>
        <select
          class="form-control styledInput"
          v-model="formdata.nazISO"
          @change="listRegioni()"
        >
          <option
            v-for="(nazione, index) in nazioni"
            :key="index"
            :value="nazione.nazISO"
          >
            {{ nazione.nazDes }}
          </option>
        </select>
      </div>

      <div class="adduser_item">
        <label>{{ labelRegione }}</label>
        <select
          class="form-control styledInput"
          v-model="formdata.regione"
          @change="listProvince()"
        >
          <option
            v-for="(regione, index) in regioni"
            :key="index"
            :value="toIDRegione(regione)"
            :disabled="regione.disabled"
          >
            {{ toStringRegione(regione) }}
          </option>
        </select>
      </div>

      <div class="adduser_item">
        <label>{{ labelProvincia }}</label>
        <select
          class="form-control styledInput"
          v-model="formdata.provincia"
          @change="listComuni()"
        >
          <option
            v-for="(provincia, index) in province"
            :key="index"
            :value="toIDProvincia(provincia)"
            :disabled="provincia.disabled"
          >
            {{ toStringProvincia(provincia) }}
          </option>
        </select>
      </div>

      <div class="adduser_item" v-if="formdata.nazISO == 'IT'">
        <label>{{ labelComune }}</label>
        <select class="form-control styledInput" v-model="formdata.comIst">
          <option
            v-for="(comune, index) in comuni"
            :key="index"
            :value="comune.comIst"
            :disabled="comune.disabled"
          >
            {{ comune.comDes }}
          </option>
        </select>
      </div>

      <div class="adduser_item">
        <label>{{ labelStazione }}</label>
        <select
          class="form-control styledInput"
          v-model="formdata.quadranteID"
          @change="geoRefUp()"
        >
          <option
            v-for="(quadrante, index) in quadranti"
            :key="index"
            :value="quadrante.quadranteID"
            :disabled="quadrante.disabled"
          >
            {{ quadrante.quadranteNome }}
          </option>
        </select>
      </div>

      <div class="adduser_item">
        <label>{{ labelLatitudine }}</label>
        <span>
          <span v-if="formdata.quadranteID" @click="openMap()">
            <font-awesome-icon :icon="['fas', 'map-marker']" class="marker" />
          </span>
          <span class="spanMap">{{ aux.latitudine }}</span>
        </span>
      </div>

      <div class="adduser_item">
        <label>{{ labelLongitudine }}</label>
        <span>
          <span v-if="formdata.quadranteID" @click="openMap()">
            <font-awesome-icon :icon="['fas', 'map-marker']" class="marker" />
          </span>
          <span class="spanMap">{{ aux.longitudine }}</span>
        </span>
      </div>

      <div class="adduser_item">
        <label>{{ labelSistema }}</label>
        <select
          class="form-control styledInput"
          v-model="formdata.sistemaColturaleID"
        >
          <option
            v-for="(sistema, index) in sistColts"
            :key="index"
            :value="sistema.sistemaColturaleID"
          >
            {{ sistema.sistemaColturaleDes }}
          </option>
        </select>
      </div>

      <div class="adduser_item">
        <label>{{ labelVarieta }}</label>
        <select class="form-control styledInput" v-model="formdata.varCod">
          <option
            v-for="(varieta, index) in varietas"
            :key="index"
            :value="varieta.varCod"
          >
            {{ varieta.varDes }}
          </option>
        </select>
      </div>

      <div class="adduser_item">
        <label>{{ labelCautelaPE }}</label>
        <select
          class="form-control styledInput"
          v-model="formdata.doseInoculoPeronosporaID"
        >
          <option
            v-for="(inoculo, index) in livelliCautela"
            :key="index"
            :value="inoculo.doseInoculoID"
          >
            {{ inoculo.doseInoculoDes }}
          </option>
        </select>
      </div>

      <!-- <div class="adduser_item">
        <label>{{ labelPressioneBot }}</label>
        <select
          class="form-control styledInput"
          v-model="formdata.livelloPressioneBotriteID"
        >
          <option
            v-for="(pressione, index) in livelliPressione"
            :key="index"
            :value="pressione.livelloPressioneID"
          >
            {{ pressione.livelloPressioneDes }}
          </option>
        </select>
      </div> -->

      <div class="adduser_item">
        <label>{{ labelPresenzaMAN }}</label>
        <select
          class="form-control styledInput"
          v-model="formdata.presenzaMarciumeNero"
        >
          <option value="0">{{ labelNo }}</option>
          <option value="1">{{ labelSi }}</option>
        </select>
      </div>

      <hr />
      <button class="btn btn-primary" @click="submit">{{ labelSalva }}</button>
      <button class="btn btn-default" @click="annulla" v-if="!showNoUp">
        {{ labelAnnulla }}
      </button>
    </div>

    <div id="mapPanel" class="highWayScroll" v-else>
      <l-map
        :zoom="zoom"
        :center="[this.geoLat, this.geoLng]"
        :options="mapOptions"
        @click="addMarker"
      >
        <l-tile-layer :url="url" :attribution="attribution" />
        <l-marker :draggable="false" :icon="azIcon" :lat-lng.sync="azPosition">
        </l-marker>
        <l-marker
          v-for="marker in markers"
          :key="marker.id"
          :draggable="true"
          :icon="icon"
          :lat-lng.sync="marker.position"
          @click="confirm(marker)"
        >
        </l-marker>
      </l-map>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { icon } from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import queries from "../../mixins/queries.js";
import locales from "../../mixins/locales.js";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data() {
    return {
      isEdit: false,
      showMap: false,
      textMsg: "",
      errors: [],
      utenti: [],
      aziende: [],
      nazioni: [],
      regioni: [],
      province: [],
      comuni: [],
      quadranti: [],
      sistColts: [],
      varietas: [],
      livelliCautela: [],
      livelliPressione: [],
      qUp: {},
      aux: {},
      desVarietaFilter: "",
      formdata: {
        UPDes: "",
        UPSup: "",
        proIst: "",
        comIst: "",
        quadranteID: "",
        IDCli: "",
        pIVAAzi: "",
        nazISO: "",
        regIst: "",
        sistemaColturaleID: "",
        varCod: "",
        doseInoculoPeronosporaID: "",
        presenzaMarciumeNero: "",
        nuts2Code: "",
        nuts3Code: "",
        provincia: "",
        regione: "",
        livelloPressioneBotriteID: ""
      },
      marker: "",
      zoom: 16,
      url:
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      attribution: "Tiles &copy; Esri",
      mapOptions: {
        zoomSnap: 0.5
      },
      azPosition: {},
      markers: [],
      clickable: false,
      icon: icon({
        iconUrl: "/images/agrishare/horta/ico/svg-agego/geoLocalAzi.svg",
        iconSize: [40, 40]
      }),
      azIcon: icon({
        iconUrl: "/images/agrishare/horta/ico/svg-agego/antenna.svg",
        iconSize: [30, 30]
      })
    };
  },
  mixins: [queries, locales],
  computed: {
    ...mapGetters({
      itemInfo: "itemInfo",
      geoLng: "geoLng",
      geoLat: "geoLat",
      showNoUp: "showNoUp",
      token: "token",
      showMsg: "showMsg",
      insertMsg: "insertMsg",
      reteID: "reteID",
      globalLocale: "globalLocale",
      varCodService: "varCodService"
    })
  },
  methods: {
    ...mapMutations([
      "setCompToRender",
      "setGeoLat",
      "setGeoLng",
      "setItemInfo",
      "setInsertMsg",
      "setShowMsg"
    ]),
    submit() {
      if (this.checkForm()) {
        this.formdata.latitudine = this.aux.latitudine;
        this.formdata.longitudine = this.aux.longitudine;
        this.formdata.colCod = this.varCodService;

        let nuts2 = "";
        let nuts3 = "";
        let regIst = "";
        let proIst = "";
        if (this.formdata.nazISO != "IT") {
          nuts2 = this.formdata.regione;
          nuts3 = this.formdata.provincia;
        } else {
          regIst = this.formdata.regione;
          proIst = this.formdata.provincia;
        }

        let sup = this.formdata.UPSup;
        if (sup != null && sup != undefined && sup.toString().indexOf(",") > -1)
          sup = sup.toString().replace(",", ".");

        if (this.isEdit) {
          this.mutationUpdateUp(
            this.token,
            this.formdata.UPID,
            sup,
            this.formdata.IDCli,
            this.formdata.pIVAAzi,
            this.formdata.UPDes,
            this.formdata.nazISO,
            regIst ? regIst : "",
            proIst ? proIst : "",
            this.formdata.comIst ? this.formdata.comIst : "",
            nuts2 ? nuts2 : "",
            nuts3 ? nuts3 : "",
            this.formdata.colCod,
            this.formdata.varCod,
            this.formdata.quadranteID,
            this.formdata.latitudine,
            this.formdata.longitudine,
            this.formdata.sistemaColturaleID,
            this.formdata.doseInoculoPeronosporaID,
            this.formdata.presenzaMarciumeNero,
            this.formdata.livelloPressioneBotriteID
          )
            .then(response => {
              if (response.data?.errors?.length > 0) {
                let msg = response.data?.errors?.[0].message;
                this.setShowMsg(true);
                this.setInsertMsg(msg);

                let message = {
                  title: this.labelErrorMsg,
                  body: msg
                };

                let options = {
                  okText: this.labelOkBtn
                };

                this.$dialog.alert(message, options);
                window.scrollTo(0, 0);
              } else {
                this.setCompToRender("compUp");
                this.setInsertMsg(this.labelOkSaveMsg);
                this.setShowMsg(true);
              }
            })
            .catch(error => {
              console.log(error);
              this.setInsertMsg(this.labelErrorSaveMsg);
              window.scrollTo(0, 0);
            });
        } else {
          this.mutationInsertUp(
            this.token,
            this.formdata.IDCli,
            sup,
            this.formdata.pIVAAzi,
            this.formdata.UPDes,
            this.formdata.nazISO,
            regIst ? regIst : "",
            proIst ? proIst : "",
            this.formdata.comIst ? this.formdata.comIst : "",
            nuts2 ? nuts2 : "",
            nuts3 ? nuts3 : "",
            this.formdata.colCod,
            this.formdata.varCod,
            this.formdata.quadranteID,
            "8",
            "1",
            this.formdata.latitudine,
            this.formdata.longitudine,
            this.formdata.sistemaColturaleID,
            this.formdata.doseInoculoPeronosporaID,
            this.formdata.presenzaMarciumeNero,
            this.formdata.livelloPressioneBotriteID
          )
            .then(response => {
              if (response.data?.errors?.length > 0) {
                this.setShowMsg(true);
                this.setInsertMsg(this.labelErrorSaveMsg);
                window.scrollTo(0, 0);
              } else {
                this.setCompToRender("compUp");
                this.setInsertMsg(this.labelOkSaveMsg);
                this.setShowMsg(true);
              }
            })
            .catch(() => {
              this.setInsertMsg(this.labelErrorSaveMsg);
              window.scrollTo(0, 0);
            });
        }
      } else {
        let message = {
          title: this.labelErrorMsg,
          body: this.errors
        };

        let options = {
          okText: this.labelOkBtn
        };

        this.$dialog.alert(message, options);
        window.scrollTo(0, 0);
      }
    },
    fillDropDown() {
      this.retrieveUpDropDownInfo(
        this.token,
        this.globalLocale,
        this.varCodService,
        this.formdata.nazISO == "IT" ? "" : this.formdata.nazISO,
        this.formdata.nazISO == "IT" ? "regDes" : "nuts2Des",
        this.formdata.nazISO == "IT" ? this.formdata.regIst : "",
        this.formdata.nazISO == "IT" ? "" : this.formdata.nuts2Code,
        this.formdata.nazISO == "IT" ? "proDes" : "nuts3Des",
        this.formdata.proIst,
        this.reteID ? this.reteID : "VT"
      )
        .then(response => {
          return response.data?.data;
        })
        .then(data => {
          const listaUtenti = data.formNuovaUP?.listaUtenti;
          this.utenti = listaUtenti;
          const listaNazioni = data.formNuovaUP?.listaNazioni;
          this.nazioni = listaNazioni;
          const listaVarieta = data.formNuovaUP?.listaVarieta;
          this.varietas = listaVarieta;
          const listaSistemi = data.formNuovaUP?.listaSistemiColturali;
          this.sistColts = listaSistemi;
          const listaInoculi = data.formNuovaUP?.dosiInoculoPeronospora;
          this.livelliCautela = listaInoculi;
          const listaPressione = data.formNuovaUP?.livelloPressioneBotrite;
          this.livelliPressione = listaPressione;
          const listaStazioni = data.formNuovaUP?.listaStazioni;

          if (listaStazioni.length > 0) this.quadranti = listaStazioni;
          else
            this.quadranti.push({
              quadranteNome: this.noItems,
              disabled: true
            });

          if (this.formdata.nazISO) {
            if (this.formdata.nazISO == "IT") {
              this.regioni = data.formNuovaUP?.listaRegioni?.regioni;
            } else {
              this.regioni = data.formNuovaUP?.listaRegioni?.nutsLevel2;
            }
          } else {
            this.regioni.push(
              {
                regDes: "",
                disabled: true
              },
              {
                regDes: this.noCountry,
                disabled: true
              }
            );
          }

          if (this.formdata.regIst || this.formdata.nuts2Code) {
            if (this.formdata.nazISO == "IT") {
              this.province = data.formNuovaUP?.listaProvince?.province;
            } else {
              this.province = data.formNuovaUP?.listaProvince?.nutsLevel3;
            }
          } else {
            this.province.push(
              {
                proDes: "",
                disabled: true
              },
              {
                proDes: this.noRegione,
                disabled: true
              }
            );
          }

          if (this.formdata.proIst) {
            this.comuni = data.formNuovaUP?.listaComuni;
          } else {
            this.comuni.push(
              {
                comDes: "",
                disabled: true
              },
              {
                comDes: this.noProvincia,
                disabled: true
              }
            );
          }

          const listaAziendeBy = data.formNuovaUP?.listaAziende;
          if (!this.formdata.IDCli) {
            this.aziende.push(
              {
                rgsAzienda: "",
                disabled: true
              },
              {
                rgsAzienda: this.noUser,
                disabled: true
              }
            );
          } else if (listaAziendeBy.length > 0) {
            this.aziende = listaAziendeBy;
          } else
            this.aziende.push(
              {
                rgsAzienda: "",
                disabled: true
              },
              {
                rgsAzienda: this.noItems,
                disabled: true
              }
            );

          this.completeEdit();
        })
        .catch(error => {
          console.log(error);
        });
    },
    listAziende() {
      if (this.formdata.IDCli) {
        this.aziende = [];
        const idCli = this.formdata.IDCli;
        this.retrieveAziende(this.token, idCli)
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            const listaAziendeBy = data.formNuovaUP?.listaAziende;
            if (listaAziendeBy.length > 0) this.aziende = listaAziendeBy;
            else
              this.aziende.push(
                {
                  rgsAzienda: "",
                  disabled: true
                },
                {
                  rgsAzienda: this.noItems,
                  disabled: true
                }
              );
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.aziende.push(
          {
            rgsAzienda: "",
            disabled: true
          },
          {
            rgsAzienda: this.noUser,
            disabled: true
          }
        );
      }
    },
    listRegioni() {
      this.regioni = [];
      if (this.formdata.nazISO) {
        const nazIso = this.formdata.nazISO;
        this.retrieveRegioni(
          this.token,
          nazIso == "IT" ? "" : nazIso,
          nazIso == "IT" ? "regDes" : "nuts2Des"
        )
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            let listaRegioniBy = [];
            if (nazIso == "IT") {
              listaRegioniBy = data.formNuovaUP?.listaRegioni?.regioni;
            } else {
              listaRegioniBy = data.formNuovaUP?.listaRegioni?.nutsLevel2;
            }

            this.regioni = listaRegioniBy;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.regioni.push(
          {
            regDes: "",
            disabled: true
          },
          {
            regDes: this.noCountry,
            disabled: true
          }
        );
      }
    },
    toStringRegione(regione) {
      if (regione.regDes) {
        return regione.regDes;
      } else if (regione.nuts2Des) {
        return regione.nuts2Des;
      }
    },
    toIDRegione(regione) {
      if (regione.regIst) {
        return regione.regIst;
      } else if (regione.nuts2Code) {
        return regione.nuts2Code;
      }
    },
    listProvince() {
      this.province = [];
      const nazIso = this.formdata.nazISO;
      if (this.formdata.regione) {
        this.retrieveProvince(
          this.token,
          nazIso == "IT" ? this.formdata.regione : "",
          nazIso == "IT" ? "" : this.formdata.regione,
          nazIso == "IT" ? "proDes" : "nuts3Des"
        )
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            let listaProvinceBy = {};
            if (nazIso == "IT") {
              listaProvinceBy = data.formNuovaUP?.listaProvince?.province;
            } else {
              listaProvinceBy = data.formNuovaUP?.listaProvince?.nutsLevel3;
            }
            this.province = listaProvinceBy;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.province.push(
          {
            proDes: "",
            disabled: true
          },
          {
            proDes: this.noRegione,
            disabled: true
          }
        );
      }
    },
    toStringProvincia(provincia) {
      if (provincia.proDes) {
        return provincia.proDes;
      } else if (provincia.nuts3Des) {
        return provincia.nuts3Des;
      }
    },
    toIDProvincia(provincia) {
      if (provincia.proIst) {
        return provincia.proIst;
      } else if (provincia.nuts3Code) {
        return provincia.nuts3Code;
      }
    },
    listComuni() {
      this.comuni = [];
      if (this.formdata.provincia) {
        this.retrieveComuni(this.token, this.formdata.provincia, "")
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            const listaComuniBy = data.formNuovaUP?.listaComuni;
            this.comuni = listaComuniBy;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.comuni.push(
          {
            comDes: "",
            disabled: true
          },
          {
            comDes: this.noProvincia,
            disabled: true
          }
        );
      }
    },
    listVarieta() {
      this.retrieveVarieta(this.token, this.varCodService)
        .then(response => {
          return response.data.data;
        })
        .then(data => {
          const listaVarieta = data.formNuovaUP?.listaVarieta;
          this.varietas = listaVarieta;
        })
        .catch(error => {
          console.log(error);
        });
    },
    geoRefUp() {
      if (this.formdata.quadranteID) {
        this.retrieveCoordinateStazione(this.token, this.formdata.quadranteID)
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            const listaStazioni = data.formNuovaUP?.listaStazioni[0];
            const correctionLng =
              listaStazioni.longitudineGradi < 0 ||
              listaStazioni.longitudineMin < 0 ||
              listaStazioni.longitudineSec < 0
                ? -1
                : 1;
            const correctionLat =
              listaStazioni.latitudineGradi < 0 ||
              listaStazioni.latitudineMin < 0 ||
              listaStazioni.latitudineSec < 0
                ? -1
                : 1;

            const q_lat =
              correctionLat *
              (Math.abs(listaStazioni.latitudineGradi) +
                Math.abs(listaStazioni.latitudineMin / 60) +
                Math.abs(listaStazioni.latitudineSec / 3600));

            const q_lng =
              correctionLng *
              (Math.abs(listaStazioni.longitudineGradi) +
                Math.abs(listaStazioni.longitudineMin / 60) +
                Math.abs(listaStazioni.longitudineSec / 3600));

            this.qUp = { latitudine: q_lat, longitudine: q_lng };
            this.aux = { latitudine: q_lat, longitudine: q_lng };
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    checkForm() {
      this.errors = [];
      let sup = this.formdata.UPSup;
      if (sup != null && sup != undefined && sup.toString().indexOf(",") > -1)
        sup = sup.toString().replace(",", ".");

      if (
        this.formdata.UPDes &&
        this.formdata.quadranteID &&
        this.formdata.IDCli &&
        this.formdata.pIVAAzi &&
        this.formdata.nazISO &&
        ((this.formdata.nazISO == "IT" && this.formdata.comIst) ||
          this.formdata.nazISO != "IT") &&
        this.formdata.provincia &&
        this.formdata.regione &&
        this.formdata.sistemaColturaleID &&
        this.formdata.varCod &&
        this.formdata.doseInoculoPeronosporaID &&
        this.formdata.presenzaMarciumeNero > -1 &&
        sup > 0
      ) {
        return true;
      }

      if (!this.formdata.UPDes) {
        this.errors.push(`${this.labelDescrizione} - ${this.required}`);
      }
      if (!this.formdata.provincia) {
        this.errors.push(`${this.labelProvincia} - ${this.required}`);
      }
      if (!this.formdata.comIst && this.formdata.nazISO == "IT") {
        this.errors.push(`${this.labelComune} - ${this.required}`);
      }
      if (!this.formdata.quadranteID) {
        this.errors.push(`${this.labelStazione} - ${this.required}`);
      }
      if (!this.formdata.IDCli) {
        this.errors.push(`${this.labelUtente} - ${this.required}`);
      }
      if (!this.formdata.pIVAAzi) {
        this.errors.push(`${this.labelAzienda} - ${this.required}`);
      }
      if (!this.formdata.nazISO) {
        this.errors.push(`${this.labelNazione} - ${this.required}`);
      }
      if (!this.formdata.regione) {
        this.errors.push(`${this.labelRegione} - ${this.required}`);
      }
      if (!this.formdata.sistemaColturaleID) {
        this.errors.push(`${this.labelSistema} - ${this.required}`);
      }
      if (!this.formdata.varCod) {
        this.errors.push(`${this.labelVarieta} - ${this.required}`);
      }
      if (!this.formdata.doseInoculoPeronosporaID) {
        this.errors.push(`${this.labelCautelaPE} - ${this.required}`);
      }
      if (this.formdata.presenzaMarciumeNero == null) {
        this.errors.push(`${this.labelPresenzaMAN} - ${this.required}`);
      }
      if (sup == null) {
        this.errors.push(`${this.labelSuperficie} - ${this.required}`);
      }
      if (sup <= 0) {
        this.errors.push(`${this.labelSuperficie} - ${this.nozero}`);
      }
    },
    cleanForm() {
      this.formdata = {
        UPDes: "",
        proIst: "",
        comIst: "",
        quadranteID: "",
        IDCli: "",
        pIVAAzi: "",
        nazISO: "",
        regIst: "",
        sistemaColturaleID: "",
        varCod: "",
        doseInoculoPeronosporaID: "",
        presenzaMarciumeNero: "",
        nuts2Code: "",
        nuts3Code: "",
        provincia: "",
        regione: "",
        livelloPressioneBotriteID: "",
        qUp: {},
        aux: {}
      };
    },
    annulla() {
      this.setCompToRender("compUp");
      this.setGeoLat("");
      this.setGeoLng("");
    },
    openMap() {
      if (this.aux) {
        this.setGeoLat(this.aux.latitudine);
        this.setGeoLng(this.aux.longitudine);
      } else {
        this.setGeoLat(this.itemInfo.latitudine);
        this.setGeoLng(this.itemInfo.longitudine);
      }

      this.azPosition = {
        lat: this.qUp?.latitudine,
        lng: this.qUp?.longitudine
      };

      this.showMap = true;
    },
    closeMap() {
      this.showMap = false;
    },
    addMarker(item) {
      if (this.markers.length == 0) {
        const newMarker = {
          position: { lat: item.latlng.lat, lng: item.latlng.lng },
          draggable: true,
          visible: true
        };
        this.markers.push(newMarker);
        setTimeout(() => {
          this.clickable = true;
        }, 1000);
      } else {
        alert(this.markerAlreadyOn);
      }
    },
    saveAndclose() {
      if (this.markers.length > 0) this.confirm(this.markers[0]);
    },
    confirm(marker) {
      if (this.clickable) {
        this.aux.latitudine = marker.position.lat;
        this.aux.longitudine = marker.position.lng;
        this.showMap = false;
      }
    },
    openHelpMap() {
      let message = {
        title: this.labelWarn,
        body: this.helpMap
      };
      let options = {
        okText: this.labelCloseBtn
      };
      this.$dialog.alert(message, options);
    },
    completeEdit() {
      if (this.itemInfo?.UPID) {
        this.markers = [];
        this.isEdit = true;
        if (this.formdata.nazISO == "IT") {
          this.formdata.regione = this.formdata.regIst;
          this.formdata.provincia = this.formdata.proIst;
        } else {
          this.formdata.regione = this.formdata.nuts2Code;
          this.formdata.provincia = this.formdata.nuts3Code;
        }

        this.aux.latitudine = this.itemInfo.latitudine;
        this.aux.longitudine = this.itemInfo.longitudine;

        const currentQuadrante = this.quadranti.filter(
          stazione => stazione.quadranteID == this.formdata.quadranteID
        );

        const correctionLng =
          currentQuadrante[0].longitudineGradi < 0 ||
          currentQuadrante[0].longitudineMin < 0 ||
          currentQuadrante[0].longitudineSec < 0
            ? -1
            : 1;
        const correctionLat =
          currentQuadrante[0].latitudineGradi < 0 ||
          currentQuadrante[0].latitudineMin < 0 ||
          currentQuadrante[0].latitudineSec < 0
            ? -1
            : 1;

        const q_lat =
          correctionLat *
          (Math.abs(currentQuadrante[0].latitudineGradi) +
            Math.abs(currentQuadrante[0].latitudineMin / 60) +
            Math.abs(currentQuadrante[0].latitudineSec / 3600));
        const q_lng =
          correctionLng *
          (Math.abs(currentQuadrante[0].longitudineGradi) +
            Math.abs(currentQuadrante[0].longitudineMin / 60) +
            Math.abs(currentQuadrante[0].longitudineSec / 3600));

        this.qUp = { latitudine: q_lat, longitudine: q_lng };

        const currentMarker = {
          position: {
            lat: this.itemInfo.latitudine,
            lng: this.itemInfo.longitudine
          },
          draggable: true,
          visible: true
        };

        this.markers.push(currentMarker);
        this.clickable = true;
      } else {
        this.cleanForm();
      }
    }
  },
  created() {
    if (this.itemInfo?.UPID) this.formdata = this.itemInfo;
    if (this.formdata.nazISO == "IT" && !this.formdata.regione) {
      this.retrieveProvince(this.token, "", "", "proDes")
        .then(response => {
          return response.data.data;
        })
        .then(data => {
          const currentProvincia = data.formNuovaUP?.listaProvince?.province.filter(
            provincia => provincia.proIst == this.formdata.proIst
          );
          this.formdata.regIst = currentProvincia?.[0].regIst;

          this.fillDropDown();
        })
        .catch(error => {
          console.log(error);
        });
    } else this.fillDropDown();
  }
};
</script>

<style scoped>
.scaled {
  transform: scale(1);
}

.highWayScroll {
  /*margin-right: 20px;*/
  height: 70vh;
}

.subTitle {
  overflow: hidden;
  margin-bottom: 5px;
}

.subTitleText {
  font-size: 16px;
  font-weight: bold;
  color: #65ac1e;
}

.error {
  color: red;
}

.info {
  font-weight: bold;
}

select {
  width: 147px;
}

.marker {
  font-size: 20px;
  color: #65ac1e;
  line-height: 15px;
  margin-left: 15px;
}

>>> .vs__dropdown-menu {
  left: -153px;
}
</style>
