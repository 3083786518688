<template>
  <div class="marginBox grigioscuro">
    <h2 v-if="isEdit">{{ labelTitleAZEdit }} - {{ formdata.rgsAzienda }}</h2>
    <h2 v-else>{{ labelTitleAZNew }}</h2>

    <div v-if="this.showMsg" class="alert alert-danger">
      <span>{{ this.insertMsg }}</span>
    </div>

    <div v-if="errors.length" class="alert alert-danger">
      <b>{{ labelErrorMsg }}</b>
      <ul>
        <li v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </div>

    <div class="adduser_item">
      <label>{{ labelUtente }}</label>
      <select
        class="form-control styledInput"
        v-model="formdata.IDCli"
        :disabled="isEdit"
      >
        <option
          v-for="(utente, index) in utenti"
          :key="index"
          :value="utente.IDCli"
        >
          {{ utente.rgsCliente }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelRGS }}</label>
      <input
        class="form-control styledInput"
        type="text"
        v-model="formdata.rgsAzienda"
      />
    </div>

    <div class="adduser_item">
      <label>{{ labelPiva }}</label>
      <input
        class="form-control styledInput"
        :disabled="isEdit"
        type="text"
        v-model="formdata.pIVAAzi"
        maxlength="11"
      />
    </div>

    <div class="adduser_item">
      <label>{{ labelCF }}</label>
      <input
        class="form-control styledInput"
        type="text"
        v-model="formdata.codiceFiscale"
        maxlength="16"
      />
    </div>

    <div class="adduser_item">
      <label>{{ labelNazione }}</label>
      <select
        class="form-control styledInput"
        v-model="formdata.nazISO"
        @change="listRegioni()"
      >
        <option
          v-for="(nazione, index) in nazioni"
          :key="index"
          :value="nazione.nazISO"
        >
          {{ nazione.nazDes }}
        </option>
      </select>
    </div>

    <div class="adduser_item" v-if="formdata.nazISO && formdata.nazISO != 'IT'">
      <label>{{ labelRegione }}</label>
      <select
        class="form-control styledInput"
        v-model="formdata.regione"
        @change="listProvince()"
      >
        <option
          v-for="(regione, index) in regioni"
          :key="index"
          :value="toIDRegione(regione)"
          :disabled="regione.disabled"
        >
          {{ toStringRegione(regione) }}
        </option>
      </select>
    </div>

    <div class="adduser_item" v-if="formdata.nazISO">
      <label>{{ labelProvincia }}</label>
      <select
        class="form-control styledInput"
        v-model="formdata.provincia"
        @change="listComuni()"
      >
        <option
          v-for="(provincia, index) in province"
          :key="index"
          :value="toIDProvincia(provincia)"
          :disabled="provincia.disabled"
        >
          {{ toStringProvincia(provincia) }}
        </option>
      </select>
    </div>

    <div class="adduser_item" v-if="formdata.nazISO == 'IT'">
      <label>{{ labelComune }}</label>
      <select class="form-control styledInput" v-model="formdata.comIst">
        <option
          v-for="(comune, index) in comuni"
          :key="index"
          :value="comune.comIst"
          :disabled="comune.disabled"
        >
          {{ comune.comDes }}
        </option>
      </select>
    </div>

    <div class="adduser_item">
      <label>{{ labelIndirizzo }}</label>
      <input
        class="form-control styledInput"
        type="text"
        v-model="formdata.indirizzo"
        maxlength="100"
      />
    </div>

    <div class="adduser_item">
      <label>{{ labelCAP }}</label>
      <input
        class="form-control styledInput"
        type="text"
        v-model="formdata.CAP"
        maxlength="5"
      />
    </div>

    <div class="adduser_item">
      <label>{{ labelEmail }}</label>
      <input
        class="form-control styledInput"
        type="text"
        v-model="formdata.eMail"
        maxlength="80"
      />
    </div>

    <hr />
    <button class="btn btn-primary" @click="submit">{{ labelSalva }}</button>
    <button class="btn btn-default" @click="annulla" v-if="!showNoAziende">
      {{ labelAnnulla }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import queries from "../../mixins/queries.js";
import locales from "../../mixins/locales.js";

export default {
  data() {
    return {
      errors: [],
      utenti: [],
      nazioni: [],
      regioni: [],
      province: [],
      comuni: [],
      isEdit: false,
      formdata: {
        rgsAzienda: "",
        IDCli: "",
        pIVAAzi: "",
        nazISO: "",
        regione: "",
        provincia: "",
        comIst: "",
        indirizzo: "",
        CAP: "",
        eMail: "",
        codiceFiscale: ""
      }
    };
  },
  mixins: [queries, locales],
  computed: {
    ...mapGetters({
      token: "token",
      itemInfo: "itemInfo",
      showNoAziende: "showNoAziende",
      showMsg: "showMsg",
      insertMsg: "insertMsg",
      globalLocale: "globalLocale"
    })
  },
  methods: {
    ...mapMutations(["setCompToRender", "setInsertMsg", "setShowMsg"]),
    submit() {
      if (this.checkForm()) {
        let nuts2 = "";
        let nuts3 = "";
        let regIst = "";
        let proIst = "";
        if (this.formdata.nazISO != "IT") {
          nuts2 = this.formdata.regione;
          nuts3 = this.formdata.provincia;
        } else {
          regIst = this.formdata.regione;
          proIst = this.formdata.provincia;
        }

        if (this.isEdit) {
          this.mutationUpdateAzienda(
            this.token,
            this.formdata.IDCli,
            this.formdata.rgsAzienda,
            this.formdata.pIVAAzi,
            this.formdata.codiceFiscale,
            this.formdata.nazISO,
            regIst ? regIst : "0",
            proIst ? proIst : "",
            this.formdata.comIst ? this.formdata.comIst : "",
            nuts2 ? nuts2 : "",
            nuts3 ? nuts3 : "",
            this.formdata.indirizzo,
            this.formdata.CAP,
            this.formdata.eMail
          )
            .then(response => {
              if (response.data?.errors?.length > 0) {
                this.setShowMsg(true);
                this.setInsertMsg(response.data?.errors?.[0].message);
              } else {
                this.setCompToRender("compAziende");
                this.setInsertMsg(this.labelOkSaveMsg);
                this.setShowMsg(true);
              }
            })
            .catch(error => {
              console.log(error);
              this.setInsertMsg(this.labelErrorSaveMsg);
            });
        } else {
          this.mutationInsertAzienda(
            this.token,
            this.formdata.IDCli,
            this.formdata.rgsAzienda,
            this.formdata.pIVAAzi,
            this.formdata.codiceFiscale,
            this.formdata.nazISO,
            regIst ? regIst : "0",
            proIst ? proIst : "",
            this.formdata.comIst ? this.formdata.comIst : "",
            nuts2 ? nuts2 : "",
            nuts3 ? nuts3 : "",
            this.formdata.indirizzo,
            this.formdata.CAP,
            this.formdata.eMail
          )
            .then(response => {
              if (response.data?.errors?.length > 0) {
                let msg = response.data?.errors?.[0].message;
                if (msg.indexOf("FITOSPA_HORTA_SCHEMA.PK_AZI") > -1) {
                  msg = "L'anagrafica è già presente nel sistema";
                }

                this.setShowMsg(true);
                this.setInsertMsg(msg);

                let message = {
                  title: this.labelErrorMsg,
                  body: msg
                };

                let options = {
                  okText: this.labelOkBtn
                };

                this.$dialog.alert(message, options);
                window.scrollTo(0, 0);
              } else {
                this.setCompToRender("compAziende");
                this.setInsertMsg(this.labelOkSaveMsg);
                this.setShowMsg(true);
              }
            })
            .catch(() => {
              this.setInsertMsg(this.labelErrorSaveMsg);
              window.scrollTo(0, 0);
            });
        }
      } else {
        let message = {
          title: this.labelErrorMsg,
          body: this.errors
        };

        let options = {
          okText: this.labelOkBtn
        };

        this.$dialog.alert(message, options);
        window.scrollTo(0, 0);
      }
    },
    fillDropDown() {
      this.retrieveAziendeDropDownInfo(
        this.token, //token
        this.itemInfo.nazISO == "IT" ? "" : this.itemInfo.nazISO, //nazIso
        this.itemInfo.nazISO == "IT" ? "" : "nuts2Des", //order by reg
        this.itemInfo.nazISO == "IT" ? "" : "", //regIst
        this.itemInfo.nazISO == "IT" ? "" : this.itemInfo.nuts2Code, //nuts2code
        this.itemInfo.nazISO == "IT" ? "proDes" : "nuts3Des", //oderbyprov
        this.itemInfo.proIst //proist
      )
        .then(response => {
          return response.data.data;
        })
        .then(data => {
          const listaUtenti = data.formNuovaAzienda?.listaUtenti;
          this.utenti = listaUtenti;

          const listaNazioni = data.formNuovaAzienda?.listaNazioni;
          this.nazioni = listaNazioni;

          if (this.formdata.nazISO) {
            if (this.formdata.nazISO == "IT") {
              this.regioni = data.formNuovaAzienda?.listaRegioni?.regioni;
            } else {
              this.regioni = data.formNuovaAzienda?.listaRegioni?.nutsLevel2;
            }
          } else {
            this.regioni.push(
              {
                regDes: "",
                disabled: true
              },
              {
                regDes: this.noCountry,
                disabled: true
              }
            );
          }

          if (this.formdata.nazISO == "IT") {
            this.province = data.formNuovaAzienda?.listaProvince?.province;
          } else {
            if (this.formdata.nuts3Code) {
              this.province = data.formNuovaAzienda?.listaProvince?.nutsLevel3;
            } else {
              this.province.push(
                {
                  proDes: "",
                  disabled: true
                },
                {
                  proDes: this.noRegione,
                  disabled: true
                }
              );
            }
          }

          if (this.formdata.proIst) {
            this.comuni = data.formNuovaAzienda?.listaComuni;
          } else {
            this.comuni.push(
              {
                comDes: "",
                disabled: true
              },
              {
                comDes: this.noProvincia,
                disabled: true
              }
            );
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    listRegioni() {
      this.regioni = [];
      if (this.formdata.nazISO) {
        const nazIso = this.formdata.nazISO;
        if (nazIso == "IT") {
          this.listProvince();
        } else {
          this.retrieveRegioni(
            this.token,
            nazIso == "IT" ? "" : nazIso,
            nazIso == "IT" ? "regDes" : "nuts2Des"
          )
            .then(response => {
              return response.data.data;
            })
            .then(data => {
              let listaRegioniBy = [];
              if (nazIso == "IT") {
                listaRegioniBy = data.formNuovaUP?.listaRegioni?.regioni;
              } else {
                listaRegioniBy = data.formNuovaUP?.listaRegioni?.nutsLevel2;
              }

              this.regioni = listaRegioniBy;
            })
            .catch(error => {
              console.log(error);
            });
        }
      } else {
        this.regioni.push(
          {
            regDes: "",
            disabled: true
          },
          {
            regDes: this.noCountry,
            disabled: true
          }
        );
      }
    },
    toStringRegione(regione) {
      if (regione.regDes) {
        return regione.regDes;
      } else if (regione.nuts2Des) {
        return regione.nuts2Des;
      }
    },
    toIDRegione(regione) {
      if (regione.regIst) {
        return regione.regIst;
      } else if (regione.nuts2Code) {
        return regione.nuts2Code;
      }
    },
    listProvince() {
      this.province = [];
      const nazIso = this.formdata.nazISO;
      if (nazIso == "IT" || (nazIso != "IT" && this.formdata.regione)) {
        this.retrieveProvince(
          this.token,
          nazIso == "IT" ? "" : "",
          nazIso == "IT" ? "" : this.formdata.regione,
          nazIso == "IT" ? "proDes" : "nuts3Des"
        )
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            let listaProvinceBy = {};
            if (nazIso == "IT") {
              listaProvinceBy = data.formNuovaUP?.listaProvince?.province;
            } else {
              listaProvinceBy = data.formNuovaUP?.listaProvince?.nutsLevel3;
            }
            this.province = listaProvinceBy;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.province.push(
          {
            proDes: "",
            disabled: true
          },
          {
            proDes: this.noRegione,
            disabled: true
          }
        );
      }
    },
    toStringProvincia(provincia) {
      if (provincia.proDes) {
        return provincia.proDes;
      } else if (provincia.nuts3Des) {
        return provincia.nuts3Des;
      }
    },
    toIDProvincia(provincia) {
      if (provincia.proIst) {
        return provincia.proIst;
      } else if (provincia.nuts3Code) {
        return provincia.nuts3Code;
      }
    },
    listComuni() {
      this.comuni = [];
      if (this.formdata.provincia) {
        this.retrieveComuni(this.token, this.formdata.provincia, "")
          .then(response => {
            return response.data.data;
          })
          .then(data => {
            const listaComuniBy = data.formNuovaUP?.listaComuni;
            this.comuni = listaComuniBy;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.comuni.push(
          {
            comDes: "",
            disabled: true
          },
          {
            comDes: this.noProvincia,
            disabled: true
          }
        );
      }
    },
    checkForm() {
      this.errors = [];
      let check = false;
      if (this.formdata.rgsAzienda) check = true;
      else {
        check = false;
      }
      if (this.formdata.IDCli) check = true;
      else {
        check = false;
      }
      if (this.formdata.pIVAAzi) check = true;
      else {
        check = false;
      }
      if (this.formdata.nazISO) check = true;
      else {
        check = false;
      }
      if (this.formdata.nazISO != "IT") {
        if (this.formdata.regione) check = true;
        else {
          check = false;
        }
      }
      if (this.formdata.provincia) check = true;
      else {
        check = false;
      }
      if (this.formdata.nazISO == "IT") {
        if (this.formdata.comIst) check = true;
        else {
          check = false;
        }
      }

      if (check) return true;

      if (!this.formdata.IDCli) {
        this.errors.push(`${this.labelUtente} - ${this.required}`);
      }
      if (!this.formdata.pIVAAzi) {
        this.errors.push(`${this.labelPiva} - ${this.required}`);
      }
      if (!this.formdata.rgsAzienda) {
        this.errors.push(`${this.labelRGS} - ${this.required}`);
      }
      if (!this.formdata.nazISO) {
        this.errors.push(`${this.labelNazione} - ${this.required}`);
      }
      if (
        this.formdata.nazISO != "" &&
        this.formdata.nazISO != "IT" &&
        !this.formdata.regione
      ) {
        this.errors.push(`${this.labelRegione} - ${this.required}`);
      }
      if (this.formdata.nazISO != "" && !this.formdata.provincia) {
        this.errors.push(`${this.labelProvincia} - ${this.required}`);
      }
      if (
        this.formdata.nazISO != "" &&
        this.formdata.nazISO == "IT" &&
        !this.formdata.comIst
      ) {
        this.errors.push(`${this.labelComune} - ${this.required}`);
      }

      //e.preventDefault();
    },
    cleanForm() {
      this.formdata = {
        rgsAzienda: "",
        IDCli: "",
        pIVAAzi: "",
        nazISO: "",
        regione: "",
        provincia: "",
        comIst: "",
        indirizzo: "",
        CAP: "",
        eMail: "",
        codiceFiscale: ""
      };
    },
    annulla() {
      this.setCompToRender("compAziende");
    }
  },
  created() {
    this.fillDropDown();

    if (this.itemInfo) {
      this.isEdit = true;
      this.formdata = this.itemInfo;
      if (this.formdata.nazISO == "IT") {
        this.formdata.regione = this.formdata.regIst;
        this.formdata.provincia = this.formdata.proIst;
      } else {
        this.formdata.regione = this.formdata.nuts2Code;
        this.formdata.provincia = this.formdata.nuts3Code;
      }
    } else {
      this.cleanForm();
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
}

.info {
  font-weight: bold;
}

select {
  width: 147px;
}
</style>
