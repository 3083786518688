// eslint-disable-next-line vue/experimental-script-setup-vars
import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueResource from "vue-resource";
import VuejsDialog from "vuejs-dialog";
import vSelect from "vue-select";
import { store } from "./Store/store.js";

import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import "vuejs-dialog/dist/vuejs-dialog.min.css";
import "vue-select/dist/vue-select.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import HortaAgrofarmLib from "horta-agrofarm-lib";
Vue.use(HortaAgrofarmLib);

import HortaPrevisioniLib from "horta-previsioni-lib";
Vue.use(HortaPrevisioniLib);

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("v-select", vSelect);

import Dialog from "./Components/Dialog.vue";
import Header from "./Components/Header_footer/Header.vue";
import Footer from "./Components/Header_footer/Footer.vue";
import "./registerServiceWorker";

Vue.component("app-dialog", Dialog);
Vue.component("app-header", Header);
Vue.component("app-footer", Footer);

Vue.use(VueResource);
Vue.use(VuejsDialog);
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
    return {
      x: 0,
      y: 0
    };
  }
});

new Vue({
  el: "#app",
  store: store,
  router,
  render: h => h(App)
});
