//import { store } from "../Store/store.js";

const CACHE_NAME = "auth";
//const TOKEN_KEY = "token";
//const FAKE_TOKEN = "sRKWQu6hCJgR25lslcf5s12FFVau0ugi";

const USERNAME = "username";
const PASSWORD = "password";
//let username = store.state.username;
//let password = store.state.password;

// Cache Storage was designed for caching
// network requests with service workers,
// mainly to make PWAs work offline.
// You can give it any value you want in this case.
const FAKE_ENDPOINT = "/fake-endpoint";

const saveToken = async (username, password) => {
  try {
    const cache = await caches.open(CACHE_NAME);
    const responseBody = JSON.stringify({
      [USERNAME]: username,
      [PASSWORD]: password
    });

    const response = new Response(responseBody);
    await cache.put(FAKE_ENDPOINT, response);
    console.log("Token saved! 🎉");
  } catch (error) {
    // It's up to you how you resolve the error
    console.log("saveToken error:", { error });
  }
};

const getToken = async () => {
  try {
    const cache = await caches.open(CACHE_NAME);
    const response = await cache.match(FAKE_ENDPOINT);

    if (!response) {
      return null;
    }

    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    // Gotta catch 'em all
    console.log("getToken error:", { error });
  }
};

const displayCachedToken = async () => {
  const cachedToken = await getToken();
  console.log({ cachedToken });
};

export default {
  methods: {
    displayToken() {
      return displayCachedToken();
    },
    getToken() {
      return getToken();
    },
    saveAuth(username, password) {
      return saveToken(username, password);
    }
  }
};
