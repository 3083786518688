<template>
  <div>
    <div class="shadowBox titleBox">
      {{ labelMeteo }} - {{ itemInfo.UPDes }}
    </div>
    <div>
      <span class="titolo_meteo">{{ stazione }}</span>
      <div>
        <iframe id="iframe" class="iframeMeteo" :src="meteoUrl"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../Store/store.js";
import { mapGetters } from "vuex";
import queries from "../mixins/queries.js";
import locales from "../mixins/locales.js";
import moment from "moment";

export default {
  data() {
    return {
      stazione: "",
      meteoUrl: "",
      section: "",
      xhtml: ""
    };
  },
  mixins: [queries, locales],
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      itemInfo: "itemInfo",
      globalLocale: "globalLocale",
      token: "token"
    })
  },
  methods: {
    retrieveStazione(up) {
      this.stazione = up.quadranteID;
    },
    resize_iframe() {
      const iframe = document.getElementById("iframe");
      iframe.style.height = "500px";
      var content = iframe.contentWindow || iframe.contentDocument;
      const h1 = content.body.scrollHeight;
      iframe.style.height = h1 + "px";
      window.scrollTo(0, 0);
    }
  },
  created() {
    this.section = this.compToRender;
    let d = new Date();
    let mmddyyyy = moment(d).format("DD/MM/YYYY");
    this.meteoUrl = `/previsioni_horta/faces/index_cta.html?up=${this.itemInfo.UPID}&quadrante=${this.itemInfo.quadranteID}&data=${mmddyyyy}&service_name=${store.state.servIdIn}&lang=${this.globalLocale}&up_attiva=${store.state.isActive}`;
  }
};
</script>

<style>
.titolo_meteo {
  color: rgb(86, 94, 163);
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: 400;
}

.iframeMeteo {
  border: 0;
  width: 100%;
  height: 100vh;
}
</style>
