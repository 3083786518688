<template>
  <div>
    <div class="shadowBox titleBox">
      {{ labelProtezione }} - {{ itemInfo.UPDes }}
    </div>
    <div>
      <button
        :class="activePanel == 0 ? 'accordion-active accordion' : 'accordion'"
        @click="changePanel(0)"
      >
        {{ labelPE }}
      </button>
      <div v-if="activePanel == 0">
        <app-dialog v-if="!isReady" />
        <!-- <div id="waitingPanel" class="waiting" v-if="!isReady">
          <div class="headerWaiting">
            {{ labeWaiting }}
          </div>
          <div class="bodyWaiting">
            {{ labelElab }}
          </div>
          <div class="loaderIcon">
            <img src="/../images/agrishare/horta/ajax-loader.gif" />
          </div>
        </div> -->
        <div class="chartPanel">
          <vue-anychart
            :options="option"
            ref="protectionChart"
            v-if="isReady"
          ></vue-anychart>

          <div class="legendPanelProtezione" v-if="isReady">
            <div
              class="protectionBox"
              :class="[
                day.protezione == 'low'
                  ? ''
                  : day.trattamento_dichiarato == 1
                  ? 'declared'
                  : day.trattamento_dichiarato == 0
                  ? 'notDeclared'
                  : ''
              ]"
              v-for="(day, index) in dati.malattia_heatmap"
              :key="index"
            ></div>
          </div>

          <div class="legendPanelProtezione" v-if="isReady">
            <div
              class="dayBox heatmapBox"
              :class="index == 10 ? 'todayBox' : ''"
              :style="`background-color:${avversita.fill}`"
              v-for="(avversita, index) in dati.malattia_heatmap"
              :key="index"
            ></div>
          </div>

          <div
            class="containerFlex legendPanelProtezione grigioscuro marginDate"
            v-if="isReady"
          >
            <div
              class="dayBox dateBoxProtezione"
              :style="index == 10 ? 'font-weight: bold' : ''"
              v-for="(day, index) in dati.malattia_heatmap"
              :key="index"
            >
              {{ convertData(day.x) }}
            </div>
          </div>

          <hr v-if="isReady" />
          <div class="bold" @click="toggleLegenda" v-if="isReady">
            <div v-show="displayLegenda" class="grigioscuro">
              {{ labelLegend }}
              <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
            </div>
            <div v-show="!displayLegenda" class="grigioscuro">
              {{ labelLegend }}
              <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
            </div>
          </div>
          <br v-if="isReady" />
          <div v-if="displayLegenda">
            <div class="bold grigioscuro" v-if="isReady">
              {{ labelEfficacia }} {{ labelProdProt }} (%)
            </div>
            <div class="legendaProdotti borderLegend" v-if="isReady">
              <div
                v-for="(prodotto, index) in products"
                :key="index"
                class="legendBox grigioscuro"
              >
                <div class="legendItem">
                  <div
                    class="declared declaredLegend"
                    :style="`background-color:${prodotto.colore}`"
                  ></div>
                </div>
                <div>
                  {{ prodotto.nome }}
                </div>
              </div>
              <div class="legendBox grigioscuro">
                <div class="legendItem" style="background-color:#003399"></div>
                <div>{{ labelPioggia }} (mm)</div>
              </div>
            </div>

            <div class="bold grigioscuro" v-if="isReady">
              {{ livelloRischioProtezione }}
            </div>
            <div class="containerFlex grigioscuro borderLegend" v-if="isReady">
              <div
                v-for="(item, index) in this.legenda"
                :key="index"
                class="legendBox"
              >
                <div
                  class="legendItem"
                  :style="`background-color:${item[0]}`"
                ></div>
                <div>
                  {{ item[1] }}
                </div>
              </div>
              <div class="legendBox">
                <div class="legendItem">
                  <div class="declared declaredLegend"></div>
                </div>
                <div>
                  {{ labelProt }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        :class="activePanel == 1 ? 'accordion-active accordion' : 'accordion'"
        @click="changePanel(1)"
      >
        {{ labelOI }}
      </button>
      <div v-if="activePanel == 1">
        <app-dialog v-if="!isReady" />
        <!-- <div id="waitingPanel" class="waiting" v-if="!isReady">
          <div class="headerWaiting">
            {{ labeWaiting }}
          </div>
          <div class="bodyWaiting">
            {{ labelElab }}
          </div>
          <div class="loaderIcon">
            <img src="/../images/agrishare/horta/ajax-loader.gif" />
          </div>
        </div> -->

        <div class="chartPanel">
          <vue-anychart
            :options="option"
            ref="protectionChart"
            v-if="activePanel == 1 && isReady"
          ></vue-anychart>

          <div class="legendPanelProtezione" v-if="isReady">
            <div
              class="protectionBox"
              :class="[
                day.protezione == 'low'
                  ? ''
                  : day.trattamento_dichiarato == 1
                  ? 'declared'
                  : day.trattamento_dichiarato == 0
                  ? 'notDeclared'
                  : ''
              ]"
              v-for="(day, index) in dati.malattia_heatmap"
              :key="index"
            ></div>
          </div>

          <div class="legendPanelProtezione" v-if="isReady">
            <div
              class="dayBox heatmapBox"
              :class="index == 10 ? 'todayBox' : ''"
              :style="`background-color:${avversita.fill}`"
              v-for="(avversita, index) in dati.malattia_heatmap"
              :key="index"
            ></div>
          </div>

          <div
            class="containerFlex legendPanelProtezione grigioscuro marginDate"
            v-if="isReady"
          >
            <div
              class="dayBox dateBoxProtezione"
              :style="index == 10 ? 'font-weight: bold' : ''"
              v-for="(day, index) in dati.malattia_heatmap"
              :key="index"
            >
              {{ convertData(day.x) }}
            </div>
          </div>

          <hr v-if="isReady" />
          <div class="bold" @click="toggleLegenda" v-if="isReady">
            <div v-show="displayLegenda" class="grigioscuro">
              {{ labelLegend }}
              <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
            </div>
            <div v-show="!displayLegenda" class="grigioscuro">
              {{ labelLegend }}
              <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
            </div>
          </div>
          <br v-if="isReady" />
          <div v-if="displayLegenda">
            <div class="bold grigioscuro" v-if="isReady">
              {{ labelEfficacia }} {{ labelProdProt }} (%)
            </div>
            <div class="legendaProdotti borderLegend" v-if="isReady">
              <div
                v-for="(prodotto, index) in products"
                :key="index"
                class="legendBox grigioscuro"
              >
                <div class="legendItem">
                  <div
                    class="declared declaredLegend"
                    :style="`background-color:${prodotto.colore}`"
                  ></div>
                </div>
                <div>
                  {{ prodotto.nome }}
                </div>
              </div>
              <div class="legendBox grigioscuro">
                <div class="legendItem" style="background-color:#003399"></div>
                <div>{{ labelPioggia }} (mm)</div>
              </div>
            </div>

            <div class="bold grigioscuro" v-if="isReady">
              {{ livelloRischioProtezione }}
            </div>
            <div class="containerFlex grigioscuro borderLegend" v-if="isReady">
              <div
                v-for="(item, index) in this.legenda"
                :key="index"
                class="legendBox"
              >
                <div
                  class="legendItem"
                  :style="`background-color:${item[0]}`"
                ></div>
                <div>
                  {{ item[1] }}
                </div>
              </div>
              <div class="legendBox">
                <div class="legendItem">
                  <div class="declared declaredLegend"></div>
                </div>
                <div>
                  {{ labelProt }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        :class="activePanel == 2 ? 'accordion-active accordion' : 'accordion'"
        v-if="showMAN"
        @click="changePanel(2)"
      >
        {{ labelMAN }}
      </button>
      <div v-if="activePanel == 2 && showMAN">
        <app-dialog v-if="!isReady" />
        <!-- <div id="waitingPanel" class="waiting" v-if="!isReady">
          <div class="headerWaiting">
            {{ labeWaiting }}
          </div>
          <div class="bodyWaiting">
            {{ labelElab }}
          </div>
          <div class="loaderIcon">
            <img src="/../images/agrishare/horta/ajax-loader.gif" />
          </div>
        </div> -->

        <div class="chartPanel">
          <vue-anychart
            :options="option"
            ref="protectionChart"
            v-if="isReady"
          ></vue-anychart>

          <div class="legendPanelProtezione" v-if="isReady">
            <div
              class="protectionBox"
              :class="[
                day.protezione == 'low'
                  ? ''
                  : day.trattamento_dichiarato == 1
                  ? 'declared'
                  : day.trattamento_dichiarato == 0
                  ? 'notDeclared'
                  : ''
              ]"
              v-for="(day, index) in dati.malattia_heatmap"
              :key="index"
            ></div>
          </div>

          <div class="legendPanelProtezione" v-if="isReady">
            <div
              class="dayBox heatmapBox"
              :class="index == 10 ? 'todayBox' : ''"
              :style="`background-color:${avversita.fill}`"
              v-for="(avversita, index) in dati.malattia_heatmap"
              :key="index"
            ></div>
          </div>

          <div
            class="containerFlex legendPanelProtezione grigioscuro marginDate"
            v-if="isReady"
          >
            <div
              class="dayBox dateBoxProtezione"
              :style="index == 10 ? 'font-weight: bold' : ''"
              v-for="(day, index) in dati.malattia_heatmap"
              :key="index"
            >
              {{ convertData(day.x) }}
            </div>
          </div>

          <hr v-if="isReady" />
          <div class="bold" @click="toggleLegenda" v-if="isReady">
            <div v-show="displayLegenda" class="grigioscuro">
              {{ labelLegend }}
              <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
            </div>
            <div v-show="!displayLegenda" class="grigioscuro">
              {{ labelLegend }}
              <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
            </div>
          </div>
          <br v-if="isReady" />
          <div v-if="displayLegenda">
            <div class="bold grigioscuro" v-if="isReady">
              {{ labelEfficacia }} {{ labelProdProt }} (%)
            </div>
            <div class="legendaProdotti borderLegend" v-if="isReady">
              <div
                v-for="(prodotto, index) in products"
                :key="index"
                class="legendBox grigioscuro"
              >
                <div class="legendItem">
                  <div
                    class="declared declaredLegend"
                    :style="`background-color:${prodotto.colore}`"
                  ></div>
                </div>
                <div>
                  {{ prodotto.nome }}
                </div>
              </div>
              <div class="legendBox grigioscuro">
                <div class="legendItem" style="background-color:#003399"></div>
                <div>{{ labelPioggia }} (mm)</div>
              </div>
            </div>

            <div class="bold grigioscuro" v-if="isReady">
              {{ livelloRischioProtezione }}
            </div>
            <div class="containerFlex grigioscuro borderLegend" v-if="isReady">
              <div
                v-for="(item, index) in this.legenda"
                :key="index"
                class="legendBox"
              >
                <div
                  class="legendItem"
                  :style="`background-color:${item[0]}`"
                ></div>
                <div>
                  {{ item[1] }}
                </div>
              </div>
              <div class="legendBox">
                <div class="legendItem">
                  <div class="declared declaredLegend"></div>
                </div>
                <div>
                  {{ labelProt }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        :class="activePanel == 3 ? 'accordion-active accordion' : 'accordion'"
        @click="changePanel(3)"
      >
        {{ labelBOT }}
      </button>
      <div v-if="activePanel == 3">
        <app-dialog v-if="!isReady" />
        <!-- <div id="waitingPanel" class="waiting" v-if="!isReady">
          <div class="headerWaiting">
            {{ labeWaiting }}
          </div>
          <div class="bodyWaiting">
            {{ labelElab }}
          </div>
          <div class="loaderIcon">
            <img src="/../images/agrishare/horta/ajax-loader.gif" />
          </div>
        </div> -->

        <div class="chartPanel">
          <div class="faseBotriteBox" v-if="isReady">A</div>
          <div class="faseBotriteBox" v-if="isReady">B</div>
          <div class="faseBotriteBox" v-if="isReady">C</div>
          <div class="faseBotriteBox" v-if="isReady">D</div>
          <div class="botriteBox" style="clear: left;" v-if="isReady">
            <span class="trattamentoBotriteBox" v-if="this.trattamenti[0]"
              >X
            </span>
          </div>
          <div class="botriteBox" v-if="isReady">
            <span class="trattamentoBotriteBox" v-if="this.trattamenti[1]"
              >X
            </span>
          </div>
          <div class="botriteBox" v-if="isReady">
            <span class="trattamentoBotriteBox" v-if="this.trattamenti[2]"
              >X
            </span>
          </div>
          <div class="botriteBox" v-if="isReady">
            <span class="trattamentoBotriteBox" v-if="this.trattamenti[3]"
              >X
            </span>
          </div>
        </div>

        <hr v-if="isReady" />
        <div class="bold" @click="toggleLegenda" v-if="isReady">
          <div v-show="displayLegenda" class="grigioscuro">
            {{ labelLegend }}
            <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
          </div>
          <div v-show="!displayLegenda" class="grigioscuro">
            {{ labelLegend }}
            <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
          </div>
        </div>
        <br v-if="isReady" />
        <div v-if="displayLegenda">
          <div class="bold grigioscuro" v-if="isReady">
            {{ labelLegend }}
          </div>
          <div class="containerFlex grigioscuro borderLegend" v-if="isReady">
            <div
              v-for="(item, index) in this.legenda"
              :key="index"
              class="legendBoxBotrite"
            >
              <div class="legendItemNoBorder">{{ item[0] }}:</div>
              <div>{{ item[1] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import VueAnychart from "./VueAnychart.vue";
import queries from "../mixins/queries.js";
import locales from "../mixins/locales.js";

export default {
  mixins: [queries, locales],
  components: {
    VueAnychart
  },
  data() {
    return {
      isReady: false,
      showMAN: false,
      activePanel: -1,
      dati: {},
      option: "",
      rainData: [],
      legenda: [],
      colors: [
        "#ef6c00",
        "#0040ff",
        "#009999",
        "#39ac73",
        "#a64dff",
        "#b2b266"
      ],
      trattamenti: [],
      products: [],
      displayLegenda: false
    };
  },
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      itemInfo: "itemInfo",
      token: "token",
      globalLocale: "globalLocale"
    })
  },
  methods: {
    changePanel(index) {
      if (this.activePanel == index) return;
      this.displayLegenda = false;
      this.isReady = false;
      this.activePanel = index;
      this.trattamenti = [];
      const protezioneSubType = index =>
        ({
          0: "peronospora",
          1: "oidio",
          2: "blackrot",
          3: "botrite"
        }[index]);

      const avversita = index =>
        ({
          0: "PE",
          1: "OI",
          2: "MAN",
          3: ""
        }[index]);

      this.fetchData(protezioneSubType(index), index, avversita(index));
    },
    fetchData(subType, index, avversita) {
      this.queryChart(
        this.token,
        "protezione",
        subType,
        this.itemInfo.UPID,
        false,
        "agrigenius",
        index == 3 ? "false" : "",
        index == 3 ? "false" : "",
        index == 3 ? "true" : "",
        index == 3 ? "false" : "",
        index == 3 ? "" : "false",
        "",
        "",
        avversita
      )
        .then(result => {
          this.dati = result.data?.data?.grafici?.result;
          for (let rain of this.dati.rain_column) {
            if (rain.value == 0) {
              rain.value = "missing";
            }
            this.rainData.push(rain);
          }
          this.trattamenti = this.dati?.botrite_trattamenti;

          this.activePanel = index;

          if (index < 3) {
            this.createLegenda();
          } else this.createLegendaBotrite();

          this.optionsChart();
          this.loaded();
        })
        .catch(error => {
          console.log(error);
        });
    },
    convertData(date) {
      if (!date.length) return "-";
      if (date) return moment(date).format("DD/MM");
      return date;
    },
    // optionsChart() {
    //   let CombineData = {
    //     chart: {
    //       type: "column",
    //       credits: false,
    //       padding: [5, 0, 0, 0],
    //       yScale: {
    //         minimum: 0,
    //         maximum: 100,
    //         ticks: {
    //           interval: 20
    //         }
    //       },
    //       scales: [
    //         {},
    //         {},
    //         {
    //           type: "linear",
    //           minimum: 0,
    //           maximum: 30,
    //           ticks: {
    //             interval: 10
    //           }
    //         }
    //       ],
    //       xAxes: [
    //         {
    //           labels: {
    //             enabled: false
    //           }
    //         }
    //       ],
    //       yAxes: [
    //         {
    //           title: {
    //             text: `${this.labelEfficacia} %`,
    //             fontColor: "green",
    //             fontWeight: "bold",
    //             padding: 0
    //           }
    //         },
    //         {
    //           orientation: "right",
    //           scale: 2,
    //           padding: 0,
    //           title: {
    //             text: `${this.labelPioggia} (mm)`,
    //             fontColor: "blue",
    //             fontWeight: "bold",
    //             padding: 0
    //           },
    //           minorTicks: {
    //             enabled: false
    //           }
    //         }
    //       ],
    //       xGrids: [
    //         {
    //           enabled: true,
    //           scale: 1
    //         }
    //       ],
    //       yGrids: [
    //         {
    //           enabled: true,
    //           scale: 0
    //         }
    //       ],
    //       series: [
    //         {
    //           seriesType: "column",
    //           name: "rain",
    //           data: this.rainData
    //         }
    //       ]
    //     }
    //   };

    //   this.products = [];
    //   this.dati.prodotti_matrix?.forEach((item, index) => {
    //     let custom_series = {
    //       seriesType: "line",
    //       name: "protezione",
    //       normal: { stroke: { color: this.colors[index], thickness: 2.5 } },
    //       data: item.protezione_line
    //     };
    //     CombineData.chart.series.push(custom_series);
    //     this.products.push({ nome: item.prodotto, colore: this.colors[index] });
    //   });

    //   this.option = CombineData;
    // },
    optionsChart() {
      let CombineData = {
        chart: {
          type: "column",
          credits: false,
          padding: [5, 0, 0, 0],
          yScale: {
            minimum: 0,
            maximum: 100,
            ticks: {
              interval: 10
            }
          },
          scales: [
            {},
            {},
            {
              type: "linear",
              minimum: 0,
              maximum: 30,
              ticks: {
                interval: 10
              }
            }
          ],
          xAxes: [
            {
              labels: {
                enabled: false
              }
            }
          ],
          yAxes: [
            {
              title: {
                text: `${this.labelEfficacia} %`,
                fontColor: "green",
                fontWeight: "bold",
                padding: 0,
                enabled: false
              }
            },
            {
              orientation: "right",
              scale: 2,
              padding: 0,
              title: {
                text: `${this.labelPioggia} (mm)`,
                fontColor: "blue",
                fontWeight: "bold",
                padding: 0,
                enabled: false
              },
              labels: {
                enabled: false
              },
              ticks: {
                stroke: "white"
              },
              minorTicks: {
                stroke: "white"
              }
            }
          ],
          xGrids: [
            {
              enabled: true,
              scale: 1
            }
          ],
          yGrids: [
            {
              enabled: true,
              scale: 0
            }
          ],
          series: [
            {
              seriesType: "column",
              name: "rain",
              data: this.rainData
            }
          ]
        }
      };

      this.products = [];
      this.dati.prodotti_matrix?.forEach((item, index) => {
        let custom_series = {
          seriesType: "line",
          name: "protezione",
          normal: { stroke: { color: this.colors[index], thickness: 2.5 } },
          data: item.protezione_line
        };
        CombineData.chart.series.push(custom_series);
        this.products.push({ nome: item.prodotto, colore: this.colors[index] });
      });

      this.option = CombineData;
    },
    loaded() {
      this.isReady = true;
    },
    createLegenda() {
      let items = new Map();

      items.set("yellowgreen", this.rischioBasso);
      items.set("gold", this.rischioMedioBasso);
      items.set("orange", this.rischioMedioAlto);
      items.set("orangered", this.rischioAlto);
      items.set("grey", "nd");

      this.legenda = items;
    },
    createLegendaBotrite() {
      let items = new Map();

      items.set("A", this.labelBotriteFaseA);
      items.set("B", this.labelBotriteFaseB);
      items.set("C", this.labelBotriteFaseC);
      items.set("D", this.labelBotriteFaseD);

      this.legenda = items;
    },
    toggleLegenda() {
      this.displayLegenda = !this.displayLegenda;
    }
  },
  created() {
    this.showMAN = this.itemInfo.presenzaMarciumeNero;
  }
};
</script>

<style scoped>
.dateBoxProtezione {
  transform: rotate(-90deg);
  width: 10px;
  border: none;
  text-align: end;
}

.marginDate {
  padding-top: 15px !important;
}

@media screen and (min-width: 825px) {
  .marginDate {
    padding-top: 25px !important;
  }
}

@media screen and (min-width: 1024px) {
  .marginDate {
    padding-top: 25px !important;
  }
}

.declared {
  background-color: blue;
}

.notDeclared {
  background-color: grey;
}

.declaredLegend {
  height: 2px;
  width: 75%;
  margin: 50% auto;
}

.prodBox {
  width: 15px;
  height: 15px;
  flex-grow: 1;
  border: 1px solid black;
}

.legendaProdotti {
  display: flex;
  flex-wrap: wrap;
}

.legendPanelProtezione {
  display: flex;
  margin: auto;
  margin-left: 2.85em;
  margin-right: 0.45em;
}

.chartPanel2 {
  border: 1px dotted gray;
  border-radius: 5px;
}

.botriteBox {
  border-top: 1px solid #6f6f6e;
  border-left: 1px solid #6f6f6e;
  border-bottom: 1px solid #6f6f6e;
  height: 30px;
  width: 60px;
  float: left;
  text-align: center;
}

.botriteBox:last-child {
  border-right: 1px solid #6f6f6e;
}

.faseBotriteBox {
  height: 15px;
  width: 62px;
  float: left;
  text-align: center;
}

.trattamentoBotriteBox {
  line-height: 30px;
  font-size: 20px;
  font-weight: bold;
}

.legendItemNoBorder {
  width: 15px;
  height: 15px;
  border: 1px solid white;
  float: left;
  margin-right: 2px;
}

.legendBoxBotrite {
  width: 50%;
  margin-bottom: 2px;
}
</style>
