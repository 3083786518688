<template>
  <div>
    <div class="shadowBox titleBox">
      {{ labelFenologia }} - {{ itemInfo.UPDes }}
    </div>
    <div class="calcHeight shadowBox" style="min-height: 84vh;">
      <div id="containerBtn" style="display:none;">
        <button class="btn-primary" @click="apriTabella">
          {{ labelEditFasifen }}
          <i class="far fa-calendar-plus"></i>
        </button>
      </div>
      <div id="container" class="chartPanel" style="display:none;">
        <vue-anychart :options="option" ref="protectionChart"></vue-anychart>
        <div class="legendPanel">
          <div
            class="prodBox"
            :class="index == 7 ? 'todayBox' : ''"
            :style="`background-color:${avversita.fill}`"
            v-for="(avversita, index) in dati.malattia_heatmap"
            :key="index"
          ></div>
        </div>

        <div class="bold legendPanelFenologia grigioscuro">
          {{ labelHeatMapEmFoglie }}
        </div>
        <div class="containerFlex legendPanelFenologia">
          <div
            class="dayBox heatmapBox"
            :class="[index == 10 ? 'todayBox' : '']"
            :style="`background-color:${day.fill}`"
            v-for="(day, index) in dati.emissione_foglie_heatmap"
            :key="index"
          ></div>
        </div>

        <div class="bold legendPanelFenologia grigioscuro">
          {{ labelHeatMapFasiProd }}
        </div>
        <div class="containerFlex legendPanelFenologia">
          <div
            class="dayBox heatmapBox"
            :class="[index == 10 ? 'todayBox' : '']"
            :style="`background-color:${day.fill}`"
            v-for="(day, index) in dati.fasi_riproduttive_heatmap"
            :key="index"
          ></div>
        </div>

        <div class="containerFlex legendPanelFenologia grigioscuro">
          <div
            class="dayBox dateBoxFenologia"
            :style="index == 10 ? 'font-weight: bold' : ''"
            v-for="(day, index) in dati.fasi_riproduttive_heatmap"
            :key="index"
          >
            {{ customFormatter(day.x) }}
          </div>
        </div>

        <hr />
        <div class="bold" @click="toggleLegenda">
          <div v-show="displayLegenda" class="grigioscuro">
            {{ labelLegend }}
            <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
          </div>
          <div v-show="!displayLegenda" class="grigioscuro">
            {{ labelLegend }}
            <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
          </div>
        </div>
        <br />

        <div v-if="displayLegenda">
          <div class="bold grigioscuro">{{ labelLegendaMeteo }}</div>
          <div class="containerFlex borderLegend">
            <div class="legendBox">
              <div class="legendItem">
                <div
                  class="declared declaredLegend"
                  style="background-color:red"
                ></div>
              </div>
              <div>{{ labelTempChart }}</div>
            </div>
            <div class="legendBox">
              <div class="legendItem" style="background-color:#003399"></div>
              <div>{{ labelPioggiaChart }}</div>
            </div>
            <div class="legendBox">
              <div class="legendItem" style="background-color:#c5c6c6"></div>
              <div>{{ labelBagnaturaChart }}</div>
            </div>
          </div>

          <div class="bold grigioscuro">{{ labelHeatMapEmFoglie }}</div>
          <div class="containerFlex borderLegend">
            <div
              v-for="(item, index) in foglieMap"
              :key="index"
              class="legendBox"
            >
              <div
                class="legendItem"
                :style="`background-color:${item[0]}`"
              ></div>
              <div>
                {{ item[1] }}
              </div>
            </div>
          </div>

          <div class="bold grigioscuro">{{ labelHeatMapFasiProd }}</div>
          <div class="containerFlex borderLegend">
            <div
              v-for="(item, index) in fasiMap"
              :key="index"
              class="legendBox"
            >
              <div
                class="legendItem"
                style="margin-bottom: 30px;"
                :style="`background-color:${item[0]}`"
              ></div>
              <div>
                {{ item[1] }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-dialog />

      <!-- <div id="waitingPanel" class="waiting">
        <div class="headerWaiting">
          {{ labeWaiting }}
        </div>
        <div class="bodyWaiting">
          {{ labelElab }}
        </div>
        <div class="loaderIcon">
          <img src="/../images/agrishare/horta/ajax-loader.gif" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";
import VueAnychart from "./VueAnychart.vue";
import queries from "../mixins/queries.js";
import locales from "../mixins/locales.js";

export default {
  components: {
    VueAnychart
  },
  mixins: [queries, locales],
  data() {
    return {
      fenoUrl: "",
      option: "",
      dati: {},
      foglieMap: [],
      fasiMap: [],
      displayLegenda: false,
      rainData: []
    };
  },
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      itemInfo: "itemInfo",
      token: "token",
      globalLocale: "globalLocale"
    })
  },
  methods: {
    ...mapMutations(["setCompToRender"]),
    apriTabella() {
      this.setCompToRender("compTabFeno");
    },
    fetchData() {
      this.queryChart(
        this.token,
        "fenologia",
        "",
        this.itemInfo.UPID,
        false,
        "agrigenius",
        false,
        false,
        false,
        false,
        true,
        "",
        "",
        ""
      )
        .then(result => {
          this.dati = result.data?.data?.grafici?.result;

          for (let rain of this.dati.rain_column) {
            if (rain.value == 0) {
              rain.value = "missing";
            }
            this.rainData.push(rain);
          }

          this.optionsChart();
          this.createLegenda();
          this.loaded();
        })
        .catch(error => {
          console.log(error);
        });
    },
    optionsChart() {
      let CombineData = {
        chart: {
          type: "column",
          credits: false,
          padding: [5, 0, 5, 0],
          yScale: {
            //temp line
            minimum: 0,
            maximum: 40,
            ticks: {
              interval: 5
            }
          },
          scales: [
            {},
            {},
            {
              //lw area
              minimum: 0,
              maximum: 24,
              ticks: {
                interval: 4
              }
            },
            {
              //rain column
              minimum: 0,
              maximum: 40,
              ticks: {
                interval: 5
              }
            }
          ],
          xAxes: [
            {
              labels: {
                enabled: false
              }
            }
          ],
          yAxes: [
            {
              title: {
                text: "Temp. (\u00B0C)",
                fontColor: "red",
                fontWeight: "bold",
                enabled: false,
                padding: 0
              }
            },
            {
              title: {
                text:
                  "<span style='color:blue'>Pioggia (mm)</span> / <span style='color:grey'>Bagn. Fogliare (h)</span>",
                fontWeight: "bold",
                useHtml: true,
                enabled: false,
                padding: 0
              },
              labels: {
                enabled: false
              },
              ticks: {
                stroke: "white"
              },
              minorTicks: {
                stroke: "white"
              },
              scale: 3,
              orientation: "right"
            } /*,
            {
              title: {
                text: "LW",
                fontColor: "blue",
                fontWeight: "bold"
              },
              orientation: "right",
              scale: 2,
              enabled: false
            }*/
          ],
          xGrids: [
            {
              enabled: true,
              scale: 1
            }
          ],
          yGrids: [
            {
              enabled: true,
              scale: 0
            }
          ],
          series: [
            {
              seriesType: "area",
              name: "lw",
              data: this.dati.lw_area,
              yScale: 3,
              stroke: {
                color: "#c5c6c6 0.5"
              },
              fill: {
                color: "#c5c6c6 0.5"
              }
            },
            {
              seriesType: "line",
              name: "temp",
              data: this.dati.temp_line,
              stroke: {
                color: "red",
                thickness: 2
              }
            },
            {
              seriesType: "column",
              data: this.rainData,
              yScale: 3,
              name: "rain"
            }
          ]
        }
      };

      this.option = CombineData;
    },
    createLegenda() {
      let mapFoglie = new Map();
      this.dati?.emissione_foglie_heatmap?.forEach(item => {
        if (item.fill != "white") mapFoglie.set(item.fill, item.label);
      });

      this.foglieMap = mapFoglie;

      let mapFasi = new Map();
      this.dati?.fasi_riproduttive_heatmap?.forEach(item => {
        if (item.fill != "white") mapFasi.set(item.fill, item.label);
      });

      this.fasiMap = mapFasi;
    },
    emissione2Txt(heat) {
      return `${heat}a foglia`;
    },
    toggleLegenda() {
      this.displayLegenda = !this.displayLegenda;
    },
    loaded() {
      document.getElementById("waitingPanel").style.display = "none";
      document.getElementById("containerBtn").style.display = "block";
      document.getElementById("container").style.display = "block";
    },
    customFormatter(date) {
      if (!date.length) return "-";
      if (date) return moment(date).format("DD/MM");
      return date;
    }
  },
  created() {
    this.fetchData();
  }
};
</script>

<style scoped>
.declaredLegend {
  height: 2px;
  width: 75%;
  margin: 50% auto;
}

.dateBoxFenologia {
  transform: rotate(-90deg);
  transform-origin: 17px 17px;
  height: 35px;
  width: 10px;
  border: none;
  text-align: end;
}

.legendPanelFenologia {
  display: flex;
  margin: auto;
  margin-left: 2.25em;
  margin-right: 0.5em;
}

@media screen and (min-width: 768px) {
  .dateBoxFenologia {
    transform-origin: 28px 16px;
  }
}

@media screen and (min-width: 834px) {
  .dateBoxFenologia {
    transform-origin: 35px 20px;
    height: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .dateBoxFenologia {
    transform-origin: 40px 22px;
    height: 40px;
  }
}

@media screen and (min-width: 1112px) {
  .dateBoxFenologia {
    transform-origin: 45px 26px;
    height: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .dateBoxFenologia {
    transform-origin: 62px 33px;
    height: 50px;
  }
}
</style>
