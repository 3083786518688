<template>
  <div>
    <div class="shadowBox titleBox">
      Mappa
    </div>
    <div style="height: 685px;">
      <l-map
        :zoom="zoom"
        :center="[this.geoLat, this.geoLng]"
        :options="mapOptions"
        style="height: 88%"
        @click="addMarker"
      >
        <l-tile-layer :url="url" :attribution="attribution" />
        <l-marker
          v-for="marker in markers"
          :key="marker.id"
          :draggable="true"
          :icon="icon"
          :lat-lng.sync="marker.position"
          @click="confirm(marker)"
        >
        </l-marker>
      </l-map>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { icon } from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data() {
    return {
      marker: "",
      zoom: 13,
      url:
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      attribution:
        "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
      mapOptions: {
        zoomSnap: 0.5
      },
      markers: [],
      clickable: false,
      icon: icon({
        iconUrl: "/images/agrishare/horta/ico/svg-agego/geoLocal.svg",
        iconSize: [30, 30]
      })
    };
  },
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      geoLat: "geoLat",
      geoLng: "geoLng"
    })
  },
  methods: {
    ...mapMutations(["setCompToRender", "setGeoLat", "setGeoLng"]),
    addMarker(item) {
      if (this.markers.length == 0) {
        const newMarker = {
          position: { lat: item.latlng.lat, lng: item.latlng.lng },
          draggable: true,
          visible: true
        };
        this.markers.push(newMarker);
        setTimeout(() => {
          this.clickable = true;
        }, 1000);
      } else {
        alert(
          "Maker già presente, adesso devi spostarlo nella posizione desiderata."
        );
      }
    },
    confirm(marker) {
      if (this.clickable) {
        this.setGeoLat(marker.position.lat);
        this.setGeoLng(marker.position.lng);
        this.setCompToRender("compAddUp");
      }
    }
  },
  created() {
    this.clickable = false;
  }
};
</script>

<style>
.iframe {
  border: 0;
  width: 100%;
}
</style>
