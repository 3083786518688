<template>
  <div>
    <div class="shadowBox titleBox">{{ labelRoc }} - {{ itemInfo.UPDes }}</div>
    <div>
      <div>
        <iframe id="iframe" class="iframeRoc" :src="rocUrl"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { store } from "../Store/store.js";
import locales from "../mixins/locales.js";
import queries from "../mixins/queries.js";

export default {
  data() {
    return {
      rocUrl: ""
    };
  },
  mixins: [queries, locales],
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      itemInfo: "itemInfo",
      globalLocale: "globalLocale"
    })
  },
  methods: {},
  created() {
    this.rocUrl = `/roc?up=${this.itemInfo.UPID}&serv_id_in=${store.state.servIdIn}&gias_lang=${this.globalLocale}`;
  }
};
</script>

<style>
.iframeRoc {
  border: 0;
  width: 100%;
  height: 100vh;
}
</style>
