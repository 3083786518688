<template>
  <div class="disclaimerContainer">
    <component :is="this.disclaimerToRender"></component>
    <div class="flex">
      <div class="marginMsg">
        <a class="btn-primary" @click="goBack()">{{ labelIndietro }}</a>
      </div>
      <div class="marginMsg">
        <a class="btn-default" @click="tornaSu()">{{ labelTornaSu }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import disclaimerIT from "../Components/Disclaimer/Marketing_IT.vue";
import disclaimerEN from "../Components/Disclaimer/Marketing_IT.vue";
import locales from "../mixins/locales.js";

export default {
  data() {
    return {
      disclaimerToRender: null
    };
  },
  components: { disclaimerIT, disclaimerEN },
  mixins: [locales],
  methods: {
    goBack() {
      this.$emit("eventClose");
    },
    tornaSu() {
      window.scroll(0, 0);
    }
  },
  computed: {
    ...mapGetters({
      globalLocale: "globalLocale"
    })
  },
  mounted() {
    if (this.globalLocale == "IT") {
      this.disclaimerToRender = disclaimerIT;
    } else if (this.globalLocale == "EN") {
      this.disclaimerToRender = disclaimerEN;
    } else {
      this.disclaimerToRender = disclaimerEN;
    }
  }
};
</script>
<style>
.disclaimerContainer {
  padding: 5px;
}

.fontComponent {
  font-family: "Abadi", sans-serif;
  font-size: 15px;
  text-rendering: optimizeLegibility;
  line-height: 25px;
}
</style>
